import React from 'react'
import styles from "./styles.module.scss"
import Lottie from "react-lottie-player"
import loader from "assets/loader.json"

export default function Loader({ heading, description }) {
    return (
        <div className={styles.container}>
            <Lottie
                loop
                animationData={loader}
                play
                style={{ width: 122, height: 122 }}
            />
            {heading ? <p className={styles.heading}>{heading}</p> : null}
            {description ? <p className={styles.description}>{description}</p> : null}
        </div>
    )
}
