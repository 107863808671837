import React from 'react'
import styles from "./styles.module.scss"
import blogs_data from "utils/blogs.json"
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'

export default function Blog() {
    const [currentBlog, setCurrentBlog] = useState(null)
    const [relatedBlogs, setRelatedBlogs] = useState(null)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const id = params.blog_id
        const getBlog = blogs_data.filter((item) => item.id === id)[0]
        setCurrentBlog(getBlog)

        let related_blogs = []
        getBlog.related_blogs.forEach(blog_id => {
            blogs_data.forEach((blog) => {
                if (blog_id === blog.id) {
                    related_blogs.push(blog)
                }
            })
        });
        setRelatedBlogs(related_blogs)
    }, [params])


//     <div key={index} className={styles.quote}>
//     <p>{item.text}</p>
//     <p>~ {item.author}</p>
// </div>

    return (currentBlog ?
        <div className={styles.container}>
            <div className={styles.yellow}></div>
            <div className={styles.maxWidthContainer}>
                <div className={styles.header}>
                    <h1>{currentBlog.title}</h1>
                    <img src={`https://d1shve8gekzify.cloudfront.net/${currentBlog.thumbnail}`} alt="" />
                </div>
                <div className={styles.content}>
                    {currentBlog.content.map((item, index) =>
                        item.type === 'paragraph' ?
                            <div key={index} className={styles.paragraph}>
                                {item.heading ? <h6>{item.heading}</h6> : null}
                                <p>{item.text}</p>
                            </div> : item.type === 'quote' ?
                                null
                                :
                                item.type === 'image' ?
                                    <div key={index} className={styles.image}>
                                        <img src={`https://d1shve8gekzify.cloudfront.net/${item.url}`} alt="" />
                                    </div> : item.type === 'ordered-list' ?
                                        <div key={index} className={styles.ol}>
                                            <p>{item.text}</p>
                                            <ol>
                                                {item.list.map((li, i) => <li key={i}>{li}</li>)}
                                            </ol>
                                        </div> : null
                    )}
                </div>
            </div>
            <div className={styles.relatedBlogs}>
                <div className={styles.maxWidthContainer}>
                    <h1>Related articles</h1>
                    <div className={styles.cards}>
                        {relatedBlogs.map((item) => <div key={item.id}>
                            <img src={`https://d1shve8gekzify.cloudfront.net/${item.thumbnail}`} alt="thumbnail" />
                            <p>{item.title}</p>
                            <button onClick={() => {
                                window.scrollTo(0, 0)
                                navigate(`/blogs/${item.id}`)
                            }}>Read More <FiArrowRight /></button>
                        </div>)}
                    </div>
                </div>
            </div>
        </div> : null
    )
}
