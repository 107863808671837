import clsx from "clsx";
import styles from "./styles.module.scss";

// type InputPropsType = {
//   isError?: boolean
//   label?: string | React.ReactElement
//   type: string
//   placeholder?: string
//   inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
//   additionalStyles?: React.CSSProperties
// }

export default function Input({
  isError,
  label,
  type,
  placeholder,
  inputProps,
  onChange,
  additionalStyles,
}) {
  return (
    <div
      className={clsx(styles.container, isError ? styles.error : null, additionalStyles)}
    >
      {label ? <label>{label}</label> : null}
      <input {...inputProps} onChange={onChange} type={type} placeholder={placeholder} />
    </div>
  );
}
