import styles from "./styles.module.scss";

import chandan from "assets/founders/chandan.png";
import nicole from "assets/founders/nicole.png";
import rakesh from "assets/founders/rakesh.png";

import { AiFillLinkedin } from "react-icons/ai";

export default function Founders() {
  return (
    <div className={styles.container}>
      <div className={styles.maxWidthContainer}>
        <h2>
          Meet our <span>founder</span> & <span>startup advisors</span>
        </h2>

        <div className={styles.founders}>
          <div className={styles.founder}>
            <img src={chandan} alt="" />
            <div>
              <p>Chandan Sheth</p>
              <div
                onClick={() => window.open("https://www.linkedin.com/in/chandansheth/")}
              >
                <AiFillLinkedin />
              </div>
            </div>
            <p className={styles.title}>Founder, PlateUp</p>
            <p className={styles.description}>
              Former Engineering Lead at Google and Uber. He is passionate about
              lifestyle-as-medicine, inspired by its impact on his father’s health.
            </p>
          </div>
          <div className={styles.founder}>
            <img src={nicole} alt="" />
            <div>
              <p>Nicole Harkin, MD, FACC</p>
              <div
                onClick={() => window.open("https://www.linkedin.com/in/nicole-harkin/")}
              >
                <AiFillLinkedin />
              </div>
            </div>
            <p className={styles.title}>Startup Advisor</p>
            <p className={styles.description}>
              Board certified in Internal Medicine, Cardiology, Echocardiography, Nuclear
              Cardiology, and Clinical Lipidology.
            </p>
          </div>
          <div className={styles.founder}>
            <img src={rakesh} alt="" />
            <div>
              <p>Rak Jotwani, MD, DipABLM</p>
              <div onClick={() => window.open("https://www.linkedin.com/in/doctorrak/")}>
                <AiFillLinkedin />
              </div>
            </div>
            <p className={styles.title}>Startup Advisor</p>
            <p className={styles.description}>
              Founder and Lifestyle Medicine physician. Previously Director of Lifestyle
              Medicine at Kaiser Permanente.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
