import React from 'react'
import styles from "./styles.module.scss"

import { TbDiscountCheckFilled } from 'react-icons/tb'
import moment from 'moment'

export default function AlreadyPaid({amount, medical_practice_name, service_date, composite_bill_id, payment_date, payment_method}) {
    return (
        <div className={styles.container}>
            <div className={styles.tick}>
                <TbDiscountCheckFilled />
                <p>Bill already paid</p>
            </div>
            <p className={styles.amount}>Amount: <span>${amount}</span></p>
            <p className={styles.issuer}>Issuer: {medical_practice_name}</p>
            <p className={styles.serviceDate}>Service date: {moment(service_date).format("MMM Do YYYY")}</p>
            <p className={styles.transactionId}>Transaction ID: {composite_bill_id}</p>
            <p className={styles.paymentDate}>Date of Payment: {moment(payment_date).format("MMM Do YYYY")}</p>
            <p className={styles.paymentMethod}>Payment Method: {payment_method}</p>
        </div>
    )
}
