import React from 'react'
import styles from "./styles.module.scss"
import { osName } from "react-device-detect";
import medical_provider from "assets/partners/medical_provider.jpg"
import employer from "assets/partners/employer.jpg"
import patient from "assets/partners/patient.jpg"
import { FiArrowRight } from "react-icons/fi"
import { HashLink } from 'react-router-hash-link';

const PARTNERS = [
    {
        id: 1,
        id_link: "medicalproviders",
        before_title: "Are you a",
        title: "medical provider",
        description: "Want to learn more about how PlateUp can help you build a healthier ecosystem for your patients? Schedule a call with us to discuss your unique needs and explore how PlateUp can fit into your medical practice.",
        button_text: "Get in touch",
        button_icon: <FiArrowRight />,
        hash_link: "#contactus",
        image: medical_provider,
        alt_text: "A doctor working on a laptop",
        color: "#2F68C1",
        button_color: "#CCDBF1",
        hover_class: "yellow",
        button_text_color: "#0149B8",
        button_link: "https://calendar.app.google/GUQzehatikKB28Ey6"
    },
    {
        id: 2,
        id_link: "employers",
        before_title: "Are you an",
        title: "employer",
        description: "Curious to learn how you can support your employees to lead healthier lives resulting in a happier, more productive workforce? Schedule a call with us to discuss how PlateUp can help you with these goals.",
        button_text: "Get in touch",
        button_icon: <FiArrowRight />,
        hash_link: "#contactus",
        image: employer,
        alt_text: "Two employees chatting happily",
        color: "#2F68C1",
        button_color: "#CCDBF1",
        hover_class: "yellow",
        button_text_color: "#0149B8",
        button_link: "https://calendar.app.google/GUQzehatikKB28Ey6"
    },
    {
        id: 3,
        id_link: "normalusers",
        before_title: "Or just want to",
        title: "improve your health",
        description: "PlateUp can help you achieve your most important goals by creating a happier, healthier you. Download the app today for  personalized nutrition plans, expert coaching, and a supportive community.",
        button_text: "Download the app",
        image: patient,
        alt_text: "A person trekking on a mountain",
        color: "#2F68C1",
        button_color: "#000000",
        hover_class: "black",
        button_text_color: "#FFFFFF",
        button_link: "app_download"
    },
]


export default function GetInTouch() {


    function openLink(link) {
        if (link !== "app_download") {
            window.open(link)
        } else {
            const os = osName.toLowerCase();
            if (os === "ios") {
                window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
            } else if (os === "android") {
                window.open("https://play.google.com/store/apps/details?id=com.labz2030.reverse");
            } else {
                document.getElementById("downloadbanner").scrollIntoView();
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                {PARTNERS.map((item, index) =>
                    <div id={item.id_link} key={item.id} className={styles.partner} data-aos="fade-up" data-aos-delay={(index + 1) * 100} data-aos-once="true">
                        <div className={styles.left}>
                            <p>{item.before_title}</p>
                            <p>{item.title}</p>
                            <p>{item.description}</p>
                            {item.hash_link ? <HashLink to={item.hash_link} className={item.hover_class === "yellow" ? styles.yellow : styles.black}>{item.button_text} {item.button_icon}</HashLink> : <button
                                onClick={openLink.bind(this, item.button_link)}
                                className={item.hover_class === "yellow" ? styles.yellow : styles.black}>
                                {item.button_text} {item.button_icon}
                            </button>}

                        </div>
                        <div className={styles.right}>
                            <img src={item.image} alt={item.alt_text} />
                        </div>
                    </div>)}
            </div>
        </div>
    )
}
