import React from 'react'
import styles from "./styles.module.scss"
import { useNavigate } from "react-router-dom";
import blogs_data from "utils/blogs.json"
import { FiArrowRight } from 'react-icons/fi'

export default function Blogs() {
    const navigate = useNavigate();

    return (
        <div id="blogs" className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <h1>Read our <span>blogs</span></h1>
                <div className={styles.cards}>
                    {blogs_data.slice(0, 3).map((item, index) => <div key={item.id} data-aos="fade-up" data-aos-delay={(index + 1) * 100} data-aos-once="true">
                        <img src={`https://d1shve8gekzify.cloudfront.net/${item.thumbnail}`} alt="thumbnail" />
                        <p>{item.title}</p>
                        <button onClick={() => {
                            navigate(`/blogs/${item.id}`)
                        }}>Read More <FiArrowRight /></button>
                    </div>)}
                </div>
            </div>
        </div>
    )
}
