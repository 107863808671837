import Home from "components/landing/Home";
import triggerEvent from "firebaseConfig/firebase";
import React, { useEffect } from "react";
import { osName } from "react-device-detect";

export default function Downloads() {
  useEffect(() => {
    const os = osName.toLowerCase();
    if (os === "ios") {
      triggerEvent("redirect_to_app_store");
      window.location.href = "https://apps.apple.com/us/app/plateup-health/id1608407957";
    } else if (os === "android") {
      triggerEvent("redirect_to_play_store");
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.labz2030.reverse";
    }
  }, []);

  return <Home />;
}
