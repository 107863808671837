import React from 'react'
import styles from "./styles.module.scss"

// import background from "assets/raklanding/herobg.svg"
import hero from "assets/raklanding/hero.jpg"
import { FiArrowRight } from 'react-icons/fi'
import { HashLink } from 'react-router-hash-link'

export default function Hero() {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.text}>

                    <h1>Dr. (Rak)esh Jotwani<br />
                        <span>MD, DipABLM</span></h1>
                    <p>Empowering you with evidence-based, lifestyle medicine programs to achieve optimal health and joy.</p>
                    <HashLink to="#form">
                        <button>Take your first step <FiArrowRight /></button>
                    </HashLink>
                </div>
            </div>
            <div className={styles.right}>
                <img src={hero} alt="dr. rakesh jotwanit" />
            </div>
        </div>
    )
}
