import React from "react";
import styles from "./styles.module.scss";

import { FiArrowDownRight, FiArrowRight, FiCheck, FiChevronRight } from "react-icons/fi";
import { useState } from "react";
import { osName } from "react-device-detect";
import { HashLink } from "react-router-hash-link";

const PRICING = [
  {
    id: 1,
    client: "FOR INDIVIDUALS",
    name: "Free",
    desc: "Ideal for individuals who want to take control of their health.",
    annual: 0,
    monthly: 0,
    plan: "month",
    cta: "Download the app",
    button_link: "app_download",
    hashlink: false,
    color: "#00B084",
    features: [
      {
        id: 1,
        text: "Disease reversal programs",
      },
      {
        id: 7,
        text: "Goal based AI meal plan creator",
      },
      {
        id: 8,
        text: "AI driven habit builder",
      },
      {
        id: 2,
        text: "Health and lifestyle tracking",
      },
      {
        id: 3,
        text: "1000s of recipes and health videos",
      },
      {
        id: 4,
        text: "Personalized meal and exercise plans",
      },
      {
        id: 5,
        text: "Follow and learn from health gurus",
      },
      {
        id: 6,
        text: "Create your social support group",
      },
    ],
  },
  {
    id: 2,
    client: "FOR MEDICAL PRACTICES",
    name: "Provider",
    desc: "Ideal for providers to grow and engage their practice.",
    annual: 99,
    monthly: 99,
    plan: "month",
    // billing: "(Billed annually)",
    cta: "Get in touch",
    button_link: "https://calendar.app.google/GUQzehatikKB28Ey6",
    hashlink: true,
    link_to: "#contactus",
    highlight_cta: true,
    color: "#2B75DE",
    features: [
      {
        id: 1,
        text: "Personalized logo and theme",
      },
      {
        id: 11,
        text: "Onboard unlimited patients / clients",
      },
      {
        id: 2,
        text: "Onboard staff from your practice",
      },
      {
        id: 3,
        text: "Business Associate Agreement (BAA)",
      },
      {
        id: 4,
        text: "Secured messaging",
      },
      {
        id: 5,
        text: "Share video lectures with patients",
      },
      {
        id: 6,
        text: "Patient mobile app",
      },
      {
        id: 7,
        text: "Digitized patient handout",
      },
      {
        id: 8,
        text: "Lifestyle prescription and monitoring",
      },
      {
        id: 9,
        text: "Collect payments (FSA, HSA included)",
      },
      {
        id: 10,
        text: "Scheduling patient meetings",
      },
    ],
  },
  {
    id: 3,
    client: "FOR WHITE LABELED APP",
    name: "Custom",
    desc: "Custom app for your practice, tailored to your requirements.",
    annual: 1849,
    monthly: 749,
    plan: "month + provider plan",
    // billing: "(Billed annually)",
    cta: "Get in touch",
    button_link: "https://calendar.app.google/GUQzehatikKB28Ey6",
    hashlink: true,
    link_to: "#contactus",
    color: "#3936CF",
    features: [
      {
        id: 1,
        text: "Everything from providers plan",
        highlight: true,
      },
      {
        id: 2,
        text: "White Labeled app",
      },
      {
        id: 3,
        text: "Frequent updates for the app",
      },
      {
        id: 4,
        text: "Appstore and playstore releases",
      },
      {
        id: 5,
        text: "Dedicated support",
      },
    ],
  },
];
export default function Pricing() {
  const [toggleCard, setToggleCard] = useState(0);
  const [activePricingTab, setActivePricingTab] = useState("annual");

  function openLink(link) {
    if (link !== "app_download") {
      window.open(link);
    } else {
      const os = osName.toLowerCase();
      if (os === "ios") {
        window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
      } else if (os === "android") {
        window.open("https://play.google.com/store/apps/details?id=com.labz2030.reverse");
      } else {
        document.getElementById("downloadbanner").scrollIntoView();
      }
    }
  }

  return (
    <div className={styles.container} id="pricing">
      <div className={styles.maxWidthWrapper}>
        <h1 className={styles.heading}>
          Value driven <span>plans</span>
        </h1>
        <p className={styles.info}>
          Use PlateUp for free for yourself. Upgrade to access the app as a medical
          practice or coaching company and priority support. Choose a plan that’s right
          for you.
        </p>
        {/* <div className={styles.pricingTabs}>
                    <button className={activePricingTab === "annual" ? styles.active : null} onClick={() => setActivePricingTab("annual")}>Annual</button>
                    <button className={activePricingTab === "monthly" ? styles.active : null} onClick={() => setActivePricingTab("monthly")}>Monthly</button>
                </div>
                <p className={styles.discountInfo}><span>Save 20%</span> with annual billing.</p> */}
        <div className={styles.pricingCards}>
          {PRICING.map((item, index) => (
            <div
              key={item.id}
              className={styles.card}
              data-aos="fade-up"
              data-aos-delay={(index + 1) * 100}
              data-aos-once="true"
            >
              <p className={styles.name}>{item.name}</p>
              <p className={styles.client}>{item.client}</p>
              {/* <p className={styles.desc}>{item.desc}</p> */}
              <p className={styles.price}>
                <span>${activePricingTab === "annual" ? item.annual : item.monthly}</span>{" "}
                / {item.plan}
              </p>

              {item.billing ? <p className={styles.billing}>{item.billing}</p> : null}

              {item.hashlink ? (
                <HashLink
                  to={item.link_to}
                  className={item.highlight_cta && styles.highlight_cta}
                >
                  {item.cta} <FiArrowRight />{" "}
                </HashLink>
              ) : (
                <button
                  className={item.highlight_cta && styles.highlight_cta}
                  onClick={openLink.bind(this, item.button_link)}
                >
                  {item.cta}
                </button>
              )}

              <button
                onClick={() => {
                  setToggleCard((prev) => {
                    if (prev === item.id) {
                      return 0;
                    } else {
                      return item.id;
                    }
                  });
                }}
                className={`${styles.mobileToggle} ${
                  toggleCard == item.id ? styles.activeButton : null
                }`}
              >
                <FiChevronRight /> See details
              </button>

              <div
                className={`${styles.featureContainer} ${
                  toggleCard == item.id ? styles.activeCard : null
                }`}
              >
                {item.features.map((feature) => (
                  <div key={feature.id} className={styles.feature}>
                    <span>
                      <FiCheck />
                    </span>
                    <p className={feature.highlight && styles.highlight}>
                      {feature.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* <div className={styles.gradient}></div> */}
        </div>
      </div>
    </div>
  );
}
