import React from 'react'
import styles from "./styles.module.scss"

export default function Insurances() {

    const logos = [
        "https://d1shve8gekzify.cloudfront.net/website-assets/insurance/1.svg",
        "https://d1shve8gekzify.cloudfront.net/website-assets/insurance/2.svg",
        "https://d1shve8gekzify.cloudfront.net/website-assets/insurance/3.svg",
        "https://d1shve8gekzify.cloudfront.net/website-assets/insurance/4.svg",
        "https://d1shve8gekzify.cloudfront.net/website-assets/insurance/10.svg",
    ]
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <div className={styles.background}></div>
                <h1>Accepted <span>insurances</span></h1>
                <div className={styles.logos}>
                    {logos.slice(0, 5).map((item, index) => <img key={item} src={item} alt="logo" data-aos="fade-up" data-aos-delay={(index + 1) * 100} data-aos-once="true" />)}
                </div>
            </div>
        </div>
    )
}
