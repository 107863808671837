import clsx from "clsx";
import styles from "./styles.module.scss";

export default function Spinner({ buttonLoader, invertColor = false }) {
  return (
    <div
      className={clsx(
        styles.container,
        buttonLoader ? styles.buttonLoader : null,
        invertColor ? styles.invert : null
      )}
    ></div>
  );
}
