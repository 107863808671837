import React from 'react'
import styles from "./styles.module.scss"

import aclm from "assets/affiliates/aclm.svg"
import acc from "assets/affiliates/acc.svg"
import aspc from "assets/affiliates/aspc.svg"
import nbhwc from "assets/affiliates/nbhwc.svg"
import ohsu from "assets/affiliates/ohsu.svg"

export default function Affiliates() {
    return (
        <div id="affiliates" className={styles.container}>
            <div className={styles.maxWidthWrapper}>
                <h1>Designed <span>by doctors</span>, for doctors.</h1>
                <p>Our experts are affliated with</p>
                <div className={styles.logos}>
                    <img src={aclm} alt="aclm logo" data-aos="fade-up" data-aos-delay="100" data-aos-once="true"/>
                    <img src={acc} alt="acc logo" data-aos="fade-up" data-aos-delay="200" data-aos-once="true"/>
                    <img src={aspc} alt="aspc logo" data-aos="fade-up" data-aos-delay="300" data-aos-once="true"/>
                    <img src={ohsu} alt="ohsu logo" data-aos="fade-up" data-aos-delay="400" data-aos-once="true"/>
                    <img src={nbhwc} alt="nbhwc logo" data-aos="fade-up" data-aos-delay="500" data-aos-once="true"/>
                </div>
            </div>
        </div>
    )
}
