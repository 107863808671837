import React from 'react'
import styles from "./styles.module.scss"

import testimonial1 from "assets/raklanding/testimonial1.png"
import testimonial2 from "assets/raklanding/testimonial2.png"
import testimonial3 from "assets/raklanding/testimonial3.png"


export default function Testimonials() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <h1>Why patients love <span>Dr. Rak</span></h1>
                <swiper-container
                    id="rak_testimonials"
                    slides-per-view={1}
                    pagination="true"
                    autoplay="true"
                >
                    <swiper-slide>
                        <div className={styles.testimonialSlide}>
                            <p>“My life has completely changed for the better! I've lost 67 pounds, reversed diabetes, and lowered high BP—medication-free. No more sleep apnea machine, and my endurance is through the roof. Embracing this transformation with renewed enthusiasm!”</p>
                            <div>
                                <img src={testimonial1} alt="placeholder" />
                                <p>Amanda W.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className={styles.testimonialSlide}>
                            <p>“Dr. Rak's care has remarkably reversed my blood test results, and I feel fantastic! Each morning, I wake up invigorated, embracing life with gratitude and excitement. This transformation is truly incredible!”</p>
                            <div>
                                <img src={testimonial2} alt="placeholder" />
                                <p>Timothy C.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div className={styles.testimonialSlide}>
                            <p>“Dr. Rak's passion for optimal health is truly inspiring. With unwavering dedication, he encourages pursuing ideal well-being. I'm beyond grateful for the remarkable transformation under his guidance. Thank you, Dr. Rak, for a healthier and happier life!”</p>
                            <div>
                                <img src={testimonial3} alt="placeholder" />
                                <p>Olivia H.</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
        </div>
    )
}
