import React from "react";
import styles from "./Privacy.module.scss";
import { useNavigate } from "react-router-dom";

import logo from "assets/logo/logo.svg";

export default function Privacy() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.maxWidthWrapper}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          <img src={logo} alt="" />
          <p>PlateUP Health</p>
        </div>

        <p className={styles.heading}>Privacy Policy</p>

        <p className={styles.paragraph}>
          Welcome to PlateUp Health and thank you for using our mobile app. PlateUp Health
          is provided by ReverseLabz Inc. Please read this Privacy Policy (this “Privacy
          Policy”) carefully. To use the Services that ReverseLabz and its affiliates
          (collectively, “ReverseLabz”, “we,” “us,” or “our”) provide, you as a user of
          the Services must accept this Privacy Policy (“User”, “you”, “your”). If you do
          not consent to the terms of the Privacy Policy, please do not access or use the
          Services.
        </p>

        <p className={styles.title}>What data do we collect?</p>

        <p className={styles.paragraph}>Our Company collects the following data:</p>
        <p className={styles.paragraph}>
          <span>Account Information:</span> Name, Username, Email address, Password, and
          your profile photo
        </p>
        <p className={styles.paragraph}>
          <span>Health and other special categories of personal data:</span> To help
          improve your experience or enable certain features of the Services we are also
          collecting your Personal Health and Sensitive Information(Gender, Camera, Device
          Information, Year of Birth, Height, Weight, Diet preference, Food allergy,
          Quality of nutrition, Blood glucose level, Calorie eaten, Stress levels, Energy
          levels and Water Levels, Data related to health metrics, including fitness data
          integrated through Apple Health Kit and Google Health Connect.)
        </p>

        <p className={styles.paragraph}>
          <span>Device information:</span> When you contact us through the app, we collect
          info on when the app was first installed, last installed, memory usage of the
          app and the maker of your phone. This is used to debug issues from our end. We
          don’t collect any other information from your device.
        </p>

        <p className={styles.paragraph}>
          <span>Location information:</span> We don’t collect location information.
        </p>

        <p className={styles.paragraph}>
          <span>Usage information:</span> When you access or use our Services, we receive
          certain usage data. This includes information about your interaction with the
          Services, for example, when you view or search content, install applications or
          software, create or log into your account.
        </p>

        <p className={styles.title}>How do we collect your Information?</p>

        <p className={styles.paragraph}>
          You directly provide Our Company with most of the data we collect. We collect
          data and process data when you use our app
        </p>

        <p className={styles.title}>How will we use your Information?</p>

        <p className={styles.paragraph}>
          Our Company collects your data so that we can provide and maintain the services
        </p>

        <p className={styles.title}>Sharing of Data:</p>

        <p className={styles.paragraph}>
          • We at ReverseLabz understand that you, by utilizing the PlateUp phone
          applications, agree to share your personal health data with us, so that we can
          better tailor our modules and programs to your individual needs.
        </p>
        <p className={styles.paragraph}>
          • We recognize the value of keeping a close eye on how such personal data is
          collected and handled with a priority on compliance with privacy and data
          protection rules.
        </p>
        <p className={styles.paragraph}>
          • However, while many consumers may prefer to withhold access to their personal
          information for marketing purposes, when it comes to health care, this digital
          transformation is an essential component of the advancement of medicine and
          health care. Personal medical and contextual types of health data contribute to
          the prediction of relevant outcomes and provide essential information for
          predicting the likelihood of an individual to develop a serious condition, such
          as breast cancer, or the response of a patient to a treatment.
        </p>
        <p className={styles.paragraph}>
          • Aggregated information can help researchers developing new medicines or public
          health officials trying to prevent or control epidemics.
        </p>
        <p className={styles.paragraph}>
          • In order to achieve these goals, ReverseLabz stores the personal health data
          on its servers rented on Amazon cloud.
        </p>
        <p className={styles.paragraph}>
          • ReverseLabz further recognizes that every individual has an inherent right to
          privacy and therefore withholds the sharing of any Personally Identifiable
          components in the personal/health data of its individual users with any person
          or organization, without the prior consent of such individual users.
        </p>

        <p className={styles.title}>Provide and maintain the services:</p>

        <p className={styles.paragraph}>
          Using the information we collect, we are able to deliver the Services to you and
          honor our Terms of Service contract with you. For example, we need to use your
          information to enable the community features of the Services; and to give you
          customer support. If you use our services, we use your information to connect
          you with other users, allow you to communicate with them through our Services,
          and help you achieve your goals to lead a healthier, more active life. For
          example, the goals that you provide allow you to develop a personal plan and set
          of actions.
        </p>

        <p className={styles.title}>Improve, personalize and develop the services:</p>

        <p className={styles.paragraph}>
          We use the information we collect to improve and personalize the Services and to
          develop new ones. For example, we use the information to troubleshoot and
          protect against errors; perform data analysis and testing; conduct research and
          surveys; and develop new features and Services. We also use your information to
          make inferences and show you more relevant content. For example, we may
          personalize your recipe feed based on the goals you previously set.
        </p>

        <p className={styles.title}>Communicate with you:</p>

        <p className={styles.paragraph}>
          We use your information when needed to send you Service notifications and
          respond to you when you contact us.
        </p>

        <p className={styles.title}>Promote safety and security:</p>

        <p className={styles.paragraph}>
          We use the information we collect to promote the safety and security of the
          Services, our users, and other parties. For example, we may use the information
          to authenticate users, protect against fraud and abuse, respond to a request or
          claim, conduct audits, and enforce our terms and policies.
        </p>

        <p className={styles.title}>How do we store your data?</p>

        <p className={styles.paragraph}>
          We work hard to keep your data safe. We use a combination of technical and
          administrative controls to maintain the security of your data. This includes
          using Secure Sockets Layer (SSL) technology or similar encryption technology to
          encrypt many of our Services. No method of transmitting or storing data is
          completely secure, however. We do not accept liability for unintentional
          disclosure. In addition, persons with access to your computer, phone, or other
          mobile or other devices may be able to access the Service and information about
          you contained in the Service. If you have a security-related concerns, please
          contact{" "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@reverselabz.com"
            target="_blank"
            rel="noreferrer"
          >
            contact@reverselabz.com
          </a>
        </p>

        <p className={styles.title}>Data retention:</p>

        <p className={styles.paragraph}>
          We keep your account information, like your name, email address and password,
          for as long as your account is in existence because we need it to operate your
          account. In some cases, when you give us information for a feature of the
          Services, we delete the data after it is no longer needed for the feature. We
          keep other information, like your health logs and food logs, until you use your
          account settings or tools to delete the data or your account because we use this
          data to provide you with your personal statistics and other aspects of the
          Services. We also keep information about you and your use of the Services for 12
          months, for legal reasons, and to prevent harm, including as described in the
          “How We will Use Information?”
        </p>

        <p className={styles.title}>Marketing:</p>

        <p className={styles.paragraph}>
          Our Company is not sending any marketing related emails, nor we are sharing the
          data with any third party.
        </p>

        <p className={styles.title}>What are your data protection rights?</p>

        <p className={styles.paragraph}>
          Our Company would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p className={styles.paragraph}>
          <span>The right to access -</span> You have the right to request Our Company for
          copies of your personal data. We may charge you a small fee for this service.
        </p>
        <p className={styles.paragraph}>
          <span>The right to rectification -</span> You have the right to request that Our
          Company correct any information you believe is inaccurate. You also have the
          right to request Our Company to complete information you believe is incomplete.
        </p>
        <p className={styles.paragraph}>
          <span>The right to erasure -</span> You have the right to request that Our
          Company erase your personal data, under certain conditions. Please email your
          request to{" "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@reverselabz.com"
            target="_blank"
            rel="noreferrer"
          >
            contact@reverselabz.com.
          </a>
        </p>

        <p className={styles.paragraph}>
          <span>The right to restrict processing -</span> You have the right to request
          that Our Company restrict the processing of your personal data, under certain
          conditions.
        </p>

        <p className={styles.paragraph}>
          <span>The right to object to processing -</span> You have the right to object to
          Our Company's processing of your personal data, under certain conditions.
        </p>
        <p className={styles.paragraph}>
          <span>The right to data portability -</span> You have the right to request that
          Our Company transfer the data that we have collected directly to you, under
          certain conditions. If you make a request, we have one month to respond to you.
          If you would like to exercise any of these rights, please contact us at our
          email:{" "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@reverselabz.com"
            target="_blank"
            rel="noreferrer"
          >
            contact@reverselabz.com.
          </a>{" "}
          / Call us: +1 (770) 769-6606
        </p>

        <p className={styles.title}>How do we use cookies?</p>

        <p className={styles.paragraph}>We are not using cookies to track anything.</p>
        <p className={styles.title}>Privacy policies of other websites: </p>

        <p className={styles.paragraph}>
          The Our Company website and App may contain links to other websites. Our privacy
          policy applies only to our website and Mobile Application, so if you click on a
          link to another website, you should read their privacy policy.
        </p>

        <p className={styles.title}>Changes to our privacy policy:</p>

        <p className={styles.paragraph}>
          Our Company keeps its privacy policy under regular review and places any updates
          on this web page. This privacy policy was last updated on April 17th 2022.
        </p>
        <p className={styles.title}>How to contact us?</p>

        <p className={styles.paragraph}>
          If you have any questions about Our Company's privacy policy, the data we hold
          on you, or you would like to exercise one of your data protection rights, please
          do not hesitate to contact us. <br />
          Email us at:{" "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@reverselabz.com"
            target="_blank"
            rel="noreferrer"
          >
            contact@reverselabz.com.
          </a>{" "}
          / Call us: +1 (770) 769-6606
        </p>
      </div>
    </div>
  );
}
