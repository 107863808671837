
import React, { useState } from "react";
import styles from "./styles.module.scss"
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Loader from "../Loader";
import { toast } from "react-toastify";
import moment from "moment";


export default function CheckoutForm({billData}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true);
  const [processingPayment, setProcessingPayment] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setProcessingPayment(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://plateup.health/verify_payment",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message)
    } else {
      toast.error("An unexpected error occurred.");
    }

    setProcessingPayment(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    loader: "always"
  }

  return (
    <div className={styles.container}>
      {isLoading ? <div className={styles.spinner}>
        <Loader heading="Please wait" />
      </div> : null}
      {processingPayment ? <div className={styles.spinner}>
        <Loader heading="Processing payment" description="Please do not close this window or navigate away from this page. Your transaction is being processed securely." />
      </div> : null}
      <p className={styles.issuer}>Issuer: {billData.medical_practice_name}</p>
      <p className={styles.date}>Service date: {moment(billData.service_date).format("MMM Do YYYY")}</p>
      <p className={styles.amount}>Amount: <span>${billData.amount}</span></p>
      <p className={styles.note}>Details are withheld for your privacy. If you need more information, please contact your healthcare provider directly.</p>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} onReady={() => setIsLoading(false)} />
        <button>Pay ${billData.amount}</button>
      </form>
      <p className={styles.info}>We also accept <span>FSA</span> and <span>HSA</span> payments, for your convenience.</p>
    </div>
  )
}