import React from 'react'
import styles from "./styles.module.scss"

import food from "assets/raklanding/chronicconditions/food.svg"
import live from "assets/raklanding/chronicconditions/live.svg"
import body from "assets/raklanding/chronicconditions/body.svg"
import sleep from "assets/raklanding/chronicconditions/sleep.svg"
import mind from "assets/raklanding/chronicconditions/mind.svg"
import substance from "assets/raklanding/chronicconditions/substance.svg"

export default function ChronicConditions() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <h1>The <span>reversal and prevention</span><br />of chronic conditions is possible.</h1>

                <div className={styles.cards}>
                    <div className={styles.column}>
                        <div className={styles.card}>
                            <img src={food} alt="food" />
                            <p>Healthy nutrition</p>
                            <p>Encourage your body to desire the
                                wholesome, nutritious food it requires.</p>
                        </div>
                        <div className={styles.card}>
                            <img src={substance} alt="food" />
                            <p>Manage substance use</p>
                            <p>Discover joy-filled living, minus
                                dependency on harmful substances.</p>
                        </div>
                    </div>


                    <div className={styles.column}>
                        <div className={styles.card}>
                            <img src={body} alt="food" />
                            <p>Activate your body</p>
                            <p>Relish the vitality and delight<br />
                                stemming from physical activity.</p>
                        </div>
                        <div className={styles.card}>
                            <img src={mind} alt="food" />
                            <p>Securing mental ease</p>
                            <p>Cultivate calmness. Your mind's<br />
                                ease lets your body respond.</p>
                        </div>
                    </div>


                    <div className={styles.column}>
                        <div className={styles.card}>
                            <img src={sleep} alt="food" />
                            <p>Improve sleep quality</p>
                            <p>Attain deeper and restful sleep,<br />
                                supported by validated health studies.</p>
                        </div>
                        <div className={styles.card}>
                            <img src={live} alt="food" />
                            <p>Lead a loving life</p>
                            <p>Foster a support system powered by<br />
                                the healing essence of community.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
