import React from 'react'
import styles from "./styles.module.scss"

import google from "assets/team_logos/google.svg"
import uber from "assets/team_logos/uber.svg"
import microsoft from "assets/team_logos/microsoft.svg"
import bu from "assets/team_logos/bu.svg"
import columbia from "assets/team_logos/columbia.svg"

export default function Team() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <div className={styles.image}>
                    <img src="https://d1shve8gekzify.cloudfront.net/website-assets/partner.png" alt="" />
                </div>
                <div className={styles.text}>
                    <h1>Partners, never competitors.</h1>
                    <p>As a white-labeled solution or not, PlateUp never competes for your patients. Instead, we empower our physician network to act independently and we always put your patients' needs first.</p>
                    <p>Built by the team from:</p>
                    <div className={styles.logos}>
                        <img src={bu} alt="Boston University Logo" data-aos="fade-up" data-aos-delay="100" data-aos-once="true"/>
                        <img src={columbia} alt="Columbia University Logo" data-aos="fade-up" data-aos-delay="200" data-aos-once="true"/>
                        <img src={google} alt="Google Logo" data-aos="fade-up" data-aos-delay="300" data-aos-once="true"/>
                        <img src={uber} alt="Uber Logo" data-aos="fade-up" data-aos-delay="400" data-aos-once="true"/>
                        <img src={microsoft} alt="Microsoft Logo" data-aos="fade-up" data-aos-delay="500" data-aos-once="true"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
