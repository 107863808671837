import React from 'react'
import styles from "./styles.module.scss"
import { useNavigate } from 'react-router-dom'
import { FaCircleXmark } from 'react-icons/fa6'
import ContactUs from '../../../../_shared/ContactUs'
import { useState } from 'react'

export default function PaymentFailed({ paymentData, setOpenContactUs }) {
    const navigate = useNavigate()
    return (
        <div className={styles.container}>
            <div className={styles.tick}>
                <FaCircleXmark />
                <p>Payment failed</p>
            </div>
            <p className={styles.message}>Your payment was unsuccessful, try again<br />and double-check your card details.</p>
            <p className={styles.amount}>Amount: <span>${paymentData.amount}</span> (failed)</p>
            <p className={styles.issuer}>To: {paymentData.medical_practice_name}</p>
            <p className={styles.paymentMethod}>Payment Method: {paymentData.payment_method}</p>
            <div className={styles.buttons}>
                <button onClick={() => setOpenContactUs(true)}>Contact Us</button>
                <button onClick={() => {
                    window.location.href = `/pay_bill?billing_code=${paymentData.composite_bill_id}`
                }}>Retry payment</button>
            </div>
        </div>
    )
}
