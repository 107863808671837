import React from "react";
import styles from "./Banner.module.scss";

import plateuplogo from "assets/plateuplogo.svg";

import one from "assets/mockups/1.png"
import two from "assets/mockups/2.png"
import three from "assets/mockups/3.png"

import playstorebutton from "assets/playstorebutton.png";
import appstorebutton from "assets/appstorebutton.png";

import triggerEvent from "firebaseConfig/firebase";
import { Parallax } from "react-scroll-parallax";



export default function Banner() {

  return (
    <div className={styles.wrapper} id="downloadbanner">
      <div className={styles.background}></div>
      <div className={styles.maxWidthWrapper}>
        <div className={styles.left}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={plateuplogo} alt="" />
          </div>
          <p>PlateUp Health App</p>
          <p>Effortlessly manage your <span>patients</span> on the go!</p>
          {/* <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                triggerEvent("app_store_button_click");
                window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
              }}
            >
              <img src={appstorebutton} alt="" />
            </button>
            <button
              onClick={() => {
                triggerEvent("play_store_button_click");
                window.open(
                  "https://play.google.com/store/apps/details?id=com.labz2030.reverse"
                );
              }}
            >
              <img src={playstorebutton} alt="" />
            </button>
          </div> */}
        </div>
        <div className={styles.mockupsContainer}>
          <Parallax speed={12} className={styles.one}>
            <img src={one} alt="mockup" />
          </Parallax>
          <Parallax disabled className={styles.two}>
            <img src={two} alt="mockup" />
          </Parallax>
          <Parallax speed={-12} className={styles.three}>
            <img src={three} alt="mockup" />
          </Parallax>
        </div>
      </div>
    </div>
  );
}
