import React from 'react'
import styles from "./styles.module.scss"
import { useNavigate } from 'react-router-dom'
import { TbDiscountCheckFilled } from 'react-icons/tb'
import moment from 'moment'
import ContactUs from '../../../../_shared/ContactUs'

export default function PaymentSuccessful({ paymentData }) {
    const navigate = useNavigate()
    return (
        <div className={styles.container}>
            <div className={styles.tick}>
                <TbDiscountCheckFilled />
                <p>Payment successful</p>
            </div>
            <p className={styles.transactionId}>Transaction ID: {paymentData.composite_bill_id}</p>
            <p className={styles.paymentDate}>Date of Payment: {moment(paymentData.payment_date).format("MMM Do YYYY")}</p>
            <p className={styles.amount}>Amount Paid: <span>${paymentData.amount}</span></p>
            <p className={styles.issuer}>To: {paymentData.medical_practice_name}</p>
            <p className={styles.paymentMethod}>Payment Method: {paymentData.payment_method}</p>
            <button onClick={() => navigate("/", { "replace": true })}>Done</button>
        </div>
    )
}
