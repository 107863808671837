import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import { useEffect } from "react";
import { getRequest, patchRequest } from "utils/requests";
import { useState } from "react";
import Select from "components/_shared/Select";
import Input from "components/_shared/Input";
import Textarea from "components/_shared/Textarea";
import Radio from "components/_shared/Radio";
import Checkbox from "components/_shared/Checkbox";

import logo from "assets/plateuplogo.svg";
import { VscVerifiedFilled } from "react-icons/vsc";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import ContactUs from "components/_shared/ContactUs";

export default function HealthForm() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [formid, setFormid] = useState(null);

  const [healthForm, setHealthForm] = useState(null);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function getHealthForm(id) {
    setIsLoading(true);
    getRequest(`/v1/bookings/get_health_form?id=${id}`)
      .then((response) => {
        console.log(response);

        let unfilled_count = 0;
        response.data.form_content.forEach((item) => {
          if (item.filled_by_client && item.required) {
            if (item.type !== "checkboxes" && item.type !== "no_show_consent") {
              if (item.response === "") {
                unfilled_count++;
              }
            } else {
              let checked_count = 0;
              item.options.forEach((option) => {
                if (option.checked) {
                  checked_count++;
                }
              });
              if (checked_count === 0) unfilled_count++;
            }
          }
        });

        if (unfilled_count === 0) setIsFormFilled(true);
        setHealthForm(response.data);
      })
      .catch((err) => console.log(err));

    setIsLoading(false);
  }

  function handleFormChange(index, value, checkboxIndex) {
    let update = [...healthForm.form_content];
    if (update[index].type === "radio") {
      if (update[index].response === value) {
        update[index].response = "";
      } else {
        update[index].response = value;
      }
    } else if (update[index].type === "checkboxes" && checkboxIndex !== undefined) {
      if (update[index].options[checkboxIndex].checked) {
        update[index].options[checkboxIndex].checked = false;
      } else {
        update[index].options[checkboxIndex].checked = true;
      }
    } else if (update[index].type === "no_show_consent") {
      if (update[index].options[0].checked) {
        update[index].options[0].checked = false;
      } else {
        update[index].options[0].checked = true;
      }
    } else {
      update[index].response = value;
    }

    setHealthForm((prev) => ({ ...prev, form_content: update }));
  }

  function submitForm() {
    setIsLoading(true);

    let unfilled_count = 0;
    healthForm.form_content.forEach((item) => {
      if (item.filled_by_client && item.required) {
        if (item.type !== "checkboxes" && item.type !== "no_show_consent") {
          if (item.response === "") {
            unfilled_count++;
          }
        } else {
          let checked_count = 0;
          item.options.forEach((option) => {
            if (option.checked) {
              checked_count++;
            }
          });
          if (checked_count === 0) unfilled_count++;
        }
      }
    });

    if (unfilled_count !== 0) {
      toast.error("Please fill all the details");
    } else {
      const payload = {
        form_content: healthForm.form_content,
      };
      patchRequest(`/v1/bookings/fill_health_form?id=${formid}`, payload)
        .then((response) => {
          getHealthForm(formid);
          toast.success("Form filled");
        })
        .catch((err) => toast.error(err));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    const id = params.get("id");
    if (id) {
      setFormid(id);
      getHealthForm(id);
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="" />
        <p>PlateUp Health</p>
      </div>
      {isLoading ? (
        <div className={styles.loader}>
          <PulseLoader color="#4384E6" />
        </div>
      ) : (
        <>
          {" "}
          {healthForm && !isFormFilled ? (
            <div className={styles.form}>
              {healthForm.form_content.map((item, index) => {
                if (!item.filled_by_client) {
                  return null;
                }
                if (item.type === "input" && item.multiline === false) {
                  return (
                    <Input
                      key={index}
                      type={item.keyboard_type === "numeric" ? "number" : "text"}
                      label={
                        <p>
                          {item.label}{" "}
                          {item.required ? (
                            <span className={styles.required}>*</span>
                          ) : null}
                        </p>
                      }
                      inputProps={{
                        value: item.response,
                      }}
                      additionalStyles={styles.input}
                      placeholder={item.placeholder}
                      onChange={(e) => handleFormChange(index, e.target.value)}
                    />
                  );
                }
                if (item.type === "input" && item.multiline === true) {
                  return (
                    <Textarea
                      key={index}
                      label={
                        <p>
                          {item.label}{" "}
                          {item.required ? (
                            <span className={styles.required}>*</span>
                          ) : null}
                        </p>
                      }
                      textareaProps={{
                        value: item.response,
                      }}
                      additionalStyles={styles.textarea}
                      placeholder={item.placeholder}
                      onChange={(e) => handleFormChange(index, e.target.value)}
                    />
                  );
                }
                if (item.type === "radio") {
                  return (
                    <div key={index} className={styles.radio}>
                      <p className={styles.title}>
                        {item.label}{" "}
                        {item.required ? (
                          <span className={styles.required}>*</span>
                        ) : null}
                      </p>
                      <div className={styles.options}>
                        {item.options?.map((radio, i) => (
                          <Radio
                            key={i}
                            label={radio}
                            checked={item.response == radio ? true : false}
                            onClick={() => handleFormChange(index, radio)}
                            additionalStyles={styles.radioOption}
                          />
                        ))}
                      </div>
                    </div>
                  );
                }
                if (item.type === "dropdown") {
                  return (
                    <Select
                      key={index}
                      label={
                        <p>
                          {item.label}{" "}
                          {item.required ? (
                            <span className={styles.required}>*</span>
                          ) : null}
                        </p>
                      }
                      defaultValue={
                        item.response
                          ? { label: item.response, value: item.response }
                          : null
                      }
                      options={item.options.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      onChange={(option) => handleFormChange(index, option?.value)}
                      additionalStyles={styles.dropdown}
                      placeholder="Select"
                    />
                  );
                }
                if (item.type === "checkboxes") {
                  return (
                    <div key={index} className={styles.checkboxes}>
                      <p className={styles.title}>
                        {item.label}{" "}
                        {item.required ? (
                          <span className={styles.required}>*</span>
                        ) : null}
                      </p>
                      <div className={styles.options}>
                        {item.options?.map((checkbox, i) => (
                          <>
                            <Checkbox
                              key={i}
                              label={checkbox.label}
                              identifier={checkbox.label}
                              onChange={() => handleFormChange(index, checkbox.label, i)}
                              checked={checkbox.checked}
                              additionalStyles={styles.checkbox}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  );
                }

                if (item.type === "no_show_consent") {
                  return (
                    <div key={index} className={styles.checkboxes}>
                      <p className={styles.title}>
                        {item.label}{" "}
                        {item.required ? (
                          <span className={styles.required}>*</span>
                        ) : null}
                      </p>
                      <div className={styles.options}>
                        {item.options?.map((checkbox, i) => (
                          <Checkbox
                            key={i}
                            label={checkbox.label}
                            identifier={checkbox.label}
                            onChange={() => handleFormChange(index, checkbox.label)}
                            checked={checkbox.checked}
                            additionalStyles={styles.checkbox}
                          />
                        ))}
                      </div>
                    </div>
                  );
                }
              })}

              <button className={styles.submit} onClick={submitForm}>
                SUBMIT FORM
              </button>
              {/* {healthForm.ongoing_tips.length ? (
            <>
              <div className={styles.heading}>
                <p>Assigned reminders</p>
              </div>
              <div className={styles.tips}>
                {healthForm.ongoing_tips.map((tip, index) => (
                  <p key={index}>
                    {index + 1}. {tip}
                  </p>
                ))}
              </div>
            </>
          ) : null} */}
            </div>
          ) : (
            <div className={styles.formFilled}>
              <VscVerifiedFilled color="#23A26D" />
              <p>Health form already filled!</p>
            </div>
          )}{" "}
        </>
      )}

      <ContactUs
        subject="Message from - Website Health Form"
        additional_info={`Composite id - ${formid}`}
      />
    </div>
  );
}
