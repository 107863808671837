import { useEffect } from "react";
import Home from "components/landing/Home";
import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.min.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

import { ToastContainer } from "react-toastify";
import Privacy from "components/privacy/Privacy";
import Terms from "components/terms/Terms";
import Downloads from "components/downloads/Downloads";
import Blog from "components/blog";
import Navbar from "components/landing/Navbar";
import Footer from "components/landing/Footer";
import RakLanding from "components/RakLanding";
import BillPayment from "components/payments/BillPayment";
import VerifyPayment from "components/payments/BillPayment/VerifyPayment";
import HealthForm from "components/HealthForm";
import DeleteAccount from "components/DeleteAccountRequest";

function App() {
  const hide_navigation = ["/rak", "/pay_bill", "/verify_payment", "/health_form", "/delete-account"];
  const location = window.location.pathname;

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ParallaxProvider>
          {hide_navigation.indexOf(location) === -1 ? <Navbar /> : null}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            theme="light"
            closeOnClick
            rtl={false}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blogs/:blog_id" element={<Blog />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms-conditions" element={<Terms />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/rak" element={<RakLanding />} />
            <Route path="/pay_bill" element={<BillPayment />} />
            <Route path="/verify_payment" element={<VerifyPayment />} />
            <Route path="/health_form" element={<HealthForm />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="*" element={<Home />} />
          </Routes>
          {hide_navigation.indexOf(location) === -1? <Footer /> : null}
        </ParallaxProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
