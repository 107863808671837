import React from 'react'
import styles from "./styles.module.scss"

import { GiFlowerStar } from "react-icons/gi"
import transformation from "assets/raklanding/transformation.png"



export default function Features() {

    function openLink() {
        window.open("https://www.mamasezz.com/blogs/news/fast-food-to-plants-dr-rakesh-jotwani")
    }

    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <div className={styles.left}>
                    <h1>Dr. Rak can help you treat<br />many <span>conditions</span></h1>
                    <div className={styles.features}>
                        <div className={styles.column}>
                            <div>
                                <GiFlowerStar />
                                <p>Diabetes Type 2</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Hypertension</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Obesity & Bariatrics</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>High Cholesterol</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Osteoporosis & Bone Health</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Coronary Artery Disease</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Congestive Heart Failure</p>
                            </div>
                        </div>
                        <div className={styles.column}>
                            <div>
                                <GiFlowerStar />
                                <p>Arthritis</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Cerebrovascular Disease (strokes)</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Anemia</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>NASH</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Chronic Kidney Disease</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Sleep Apnea</p>
                            </div>
                            <div>
                                <GiFlowerStar />
                                <p>Atrial Fibrillation</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.card}>
                        <img src={transformation} alt="" />
                        <div className={styles.text}>
                            <p>Check out Dr. Rak’s transformation story!</p>
                            <button onClick={openLink}>Read more</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
