import axios from "axios";

const baseUrl = "https://rad-plateupapp-api.plateup.health";
// const baseUrl = "http://localhost:5000"

export function postRequest(endpoint, payload) {
  return axios.post(`${baseUrl}${endpoint}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getRequest(endpoint) {
  return axios.get(`${baseUrl}${endpoint}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function patchRequest(endpoint, payload) {
  return axios.patch(`${baseUrl}${endpoint}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}