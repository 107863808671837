import styles from "./styles.module.scss";
import clsx from "clsx";

export default function Button({
  text,
  icon,
  icon2,
  fullWidth,
  width,
  onClick,
  type,
  size,
  backgroundColor,
  textColor,
  borderColor,
  additionalStyles,
  disabled = false,
  borderRadius,
  padding,
}) {
  return (
    <button
      className={clsx(
        styles.button,
        type === "primary" && !disabled
          ? styles.primary
          : type === "primary" && disabled
          ? styles.primaryDisabled
          : type === "secondary"
          ? styles.secondary
          : styles.outline,
        size === "big" ? styles.big : null,
        fullWidth ? styles.fullWidth : null,
        disabled ? styles.disabled : null,
        additionalStyles
      )}
      style={{
        width: width,
        backgroundColor: backgroundColor,
        color: textColor,
        borderColor: borderColor,
        borderRadius: borderRadius,
        padding: padding,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      {text}
      {icon2 ? <div className={styles.icon2}>{icon2}</div> : null}
    </button>
  );
}
