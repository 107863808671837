import React from 'react'
import styles from "./styles.module.scss"
import CountUp from 'react-countup';


export default function Result() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthWrapper}>
            <div className={styles.background}></div>
                <div className={styles.text}>
                    <div className={styles.left}>
                        <h1>Our <span>results</span> as of {months[new Date().getMonth() - 1]} {new Date().getFullYear()}</h1>
                        <p>Aggregate health gained by
                            the PlateUp community so far, as reported by our users.</p>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.row}>
                            <div className={styles.result}>
                                <p>
                                    <CountUp
                                        className={styles.animated}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        end={37}
                                    />
                                    +</p>
                                <p>Experts on platform</p>
                            </div>
                            <div className={styles.result}>
                                <p>
                                    <CountUp
                                        className={styles.animated}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        decimals={1}
                                        end={3694.5}
                                    />
                                    <span> lbs</span></p>
                                <p>Total weight lost</p>
                            </div>
                            <div className={styles.result}>
                                <p>
                                    <CountUp
                                        className={styles.animated}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        end={673}
                                    />
                                    <span> mg/dl</span></p>
                                <p>Total blood sugar reduced</p>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.result}>
                                <p>
                                    <CountUp
                                        className={styles.animated}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        decimal={1}
                                        end={312.2}
                                    />
                                    <span> mg/dl</span></p>
                                <p>Total cholesterol LDL lost </p>
                            </div>
                            <div className={styles.result}>
                                <p>
                                    <CountUp
                                        className={styles.animated}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        decimal={1}
                                        end={88.4}
                                    />
                                </p>
                                <p>Total A1C reduced</p>
                            </div>
                            <div className={styles.result}>
                                <p>
                                    <CountUp
                                        className={styles.animated}
                                        enableScrollSpy
                                        scrollSpyOnce
                                        end={260}
                                    />
                                    <span> mmHg</span></p>
                                <p>Total systolic BP reduced</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.image}>
                    <img src="https://d1shve8gekzify.cloudfront.net/website-assets/results.jpg" alt="woman with laptop" />
                </div>
                {/* <div className={styles.gradient}></div> */}
            </div>
        </div>
    )
}
