import React from 'react';
import styles from "./styles.module.scss"
import logo from "assets/plateuplogo.svg"
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'
import PaymentSuccessful from './PaymentSuccessful';
import PaymentFailed from './PaymentFailed';
import Loader from '../Loader';
import { getRequest } from 'utils/requests';
import { toast } from 'react-toastify';
import ContactUs from '../../../_shared/ContactUs';


// const test_data = {
//     "status": "succeeded",
//     "payment_intent_id": "payment_intent_id",
//     "payment_date": "current_timestamp",
//     "amount": 500,
//     "medical_practice_name": "medical_practice.name",
//     "payment_method": "payment_record.payment_method",
//     "composite_bill_id": "asdasdas"
// }

export default function VerifyPayment() {
    const location = useLocation()
    const params = new URLSearchParams(location.search);

    const [openContactUs, setOpenContactUs] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [paymentIntentId, setPaymentIntentId] = useState(null)

    const [paymentData, setPaymentData] = useState(null)

    async function verifyBillPayment(payment_intent_id) {
        getRequest(`/v1/payments/verify_payment_for_bill?payment_intent_id=${payment_intent_id}`).then((response) => {
            setPaymentData(response.data)
        }).catch((error) => {
            toast.error("Something went wrong, please try again later.")
        })
        setIsLoading(false)
    }

    useEffect(() => {
        const payment_intent_id = params.get("payment_intent")
        setPaymentIntentId(payment_intent_id)
        if (payment_intent_id) {
            verifyBillPayment(payment_intent_id)
        }
    }, [])

    return (
        <div className={styles.container}>
            {isLoading ? <Loader /> : null}

            <div className={styles.logo}>
                <img src={logo} alt="" />
                <h1>PlateUp Health</h1>
            </div>

            {paymentData ? <>

                {paymentData.status === "succeeded" ? <PaymentSuccessful paymentData={paymentData} /> : paymentData.status === "failed" ? <PaymentFailed paymentData={paymentData} setOpenContactUs={setOpenContactUs} /> : null}

            </> : null}
            <ContactUs openModal={openContactUs} additional_info={paymentData ? paymentData.composite_bill_id : ""} />
        </div>
    )
}
