import React from "react";
import styles from "./Terms.module.scss";
import { useNavigate } from "react-router-dom";

import logo from "assets/logo/logo.svg";

export default function Terms() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.maxWidthWrapper}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          <img src={logo} alt="" />
          <p>PlateUP Health</p>
        </div>

        <p className={styles.heading}>Terms & Conditions</p>

        <p className={styles.paragraph}>
          Welcome to PlateUp Health and thank you for using our mobile app. PlateUp Health
          is provided by ReverseLabz Inc. Throughout these terms of service, ReverseLabz
          Inc. will be referred to as; “we”, “our”, “us”, “service”. “You” means any third
          party who uses our services. By enrolling (registering) and/or using our
          services you agree and accept to be bound by these terms of service. If you do
          not agree to the terms & conditions, you should immediately cease (uninstall)
          all usage of this app (/website). We may update these terms from time to time
          without prior notice. We will make reasonable efforts to notify you of any
          updates. Your continued use of our services will be deemed acceptance to amended
          or updated terms. The terms of service are only available in the English
          version. As such you should ensure to see if we have updated these terms. Our
          terms of service are available at{" "}
          <a
            href="https://www.plateup.health/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            www.plateup.health/terms-conditions
          </a>
        </p>

        <p className={styles.title}>1. DESCRIPTION OF SERVICE</p>

        <p className={styles.paragraph}>
          PlateUp Health App is an activity and lifestyle tracking health & fitness app.
          It helps you create healthy lifestyle habits by incentivizing you to adopt a
          healthy lifestyle.
        </p>

        <p className={styles.title}>1.1 USER / MEMBER CONDUCT</p>

        <p className={styles.paragraph}>
          To use PlateUp Health App, you must be aged 13 years or above. Permission of a
          parent or legal guardian is required if you are aged between 13 to 18 years. You
          may not use this app to introduce malicious software, viruses, worms, Trojan
          horses, malware, spiders and/or harmful code.
        </p>

        <p className={styles.title}>1.2 SUSPENSION & TERMINATION</p>

        <p className={styles.paragraph}>
          We, at any time in our discretion with or without notice may suspend access to
          our platform immediately in case of member mis-conduct. Re-access to the
          platform can be requested by writing to us at contact@reverselabz.com with a
          valid case of renewal.
        </p>

        <p className={styles.title}>
          1.3 GOVERNING LAW; VENUE; SEVERABILITY OF PROVISIONS
        </p>

        <p className={styles.paragraph}>
          This Service is controlled and operated by Reverselabz from our offices within
          California. Those who choose to access the Service from other locations do so on
          their own initiative and are responsible for compliance with local laws, if and
          to the extent applicable. Access to the Service from jurisdictions where the
          contents of the Service are illegal or penalized is prohibited.
        </p>
        <p className={styles.paragraph}>
          The validity, interpretation, construction and performance of these Terms of Use
          will be governed by the laws of the State of California, without regard to any
          conflicts of law provisions.
        </p>
        <p className={styles.paragraph}>
          All parts of these Terms of Use apply to the maximum extent permitted by law.
          Our failure to enforce any provision of these Terms of Use will not constitute a
          waiver of such right. We both agree that if we cannot enforce a part of this
          contract as written, then that part will be replaced with terms that most
          closely match the intent of the part we cannot enforce to the extent permitted
          by law. The invalidity of part of these Terms of Use will not affect the
          validity and enforceability of the remaining provisions. The section headings
          are for convenience and do not have any force or effect.
        </p>

        <p className={styles.title}>Provide and maintain the services:</p>

        <p className={styles.paragraph}>
          Using the information we collect, we are able to deliver the Services to you and
          honor our Terms of Service contract with you. For example, we need to use your
          information to enable the community features of the Services; and to give you
          customer support. If you use our services, we use your information to connect
          you with other users, allow you to communicate with them through our Services,
          and help you achieve your goals to lead a healthier, more active life. For
          example, the goals that you provide allow you to develop a personal plan and set
          of actions.
        </p>

        <p className={styles.title}>1.4 DISCLAIMERS</p>

        <p className={styles.paragraph}>
          The information on this service, including texts and images, is for
          informational purposes only. Users relying on Content or other information from
          the Service do so at their own risk. This service does not provide medical
          advice and is not a substitute for consultation with a healthcare professional.
          The purpose of this service is for information only, and does not constitute
          medical advice or establish a patient-physician relationship. With any questions
          regarding your specific condition, you should seek medical advice from a
          qualified healthcare professional. Do not use this service for medical diagnosis
          or treatment.
        </p>
        <p className={styles.paragraph}>
          You acknowledge and agree that there are risks associated with utilizing an
          internet based service including, but not limited to, the risk of failure of
          hardware, software and internet connections, the risk of malicious software
          introduction, and the risk that third parties may obtain unauthorized access to
          information stored within your account, but not limited to your data. You
          understand and agree that: Your use of our services is at your sole risk. Each
          party to this agreement represents and warrants it has all legal requisites and
          corporate powers to enter into this agreement; it has no agreement or
          understanding with any third party that interferes with or will interfere with
          its performance of its obligation under this agreement. ReverseLabz Inc. will
          use its best efforts to ensure that the quality of our services, information and
          other material purchased or obtained by you will meet your expectations using
          our best effort. Any errors in the software will be corrected. No advice or
          information verbal or written, we give through the platforms, websites and/or
          otherwise shall create any warranty, representation and/or guarantee not
          expressly stated herein.
        </p>
        <p className={styles.paragraph}>
          THE SERVICE IS PROVIDED ON AN “AS IS” OR “AS AVAILABLE” BASIS. ANY ACCESS TO OR
          USE OF THE SERVICE IS VOLUNTARY AND AT THE SOLE RISK OF THE USER. REVERSELABZ TO
          THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES AND CONDITIONS OF
          ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
          LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD
          PARTIES’ RIGHTS, SATISFACTORY QUALITY AND FITNESS FOR PARTICULAR PURPOSE WITH
          REGARD TO THE SERVICE, AND WITH RESPECT TO ANY INFORMATION, CONTENT, PRODUCT,
          SERVICE, MERCHANDISE OR OTHER MATERIAL PROVIDED ON OR THROUGH THE SERVICE OR THE
          PLATFORM (INCLUDING ANY CONSULTATIONS OR OTHER SERVICES YOU MAY RECEIVE FROM THE
          MEDICAL GROUP OR ITS PROVIDERS). REVERSELABZ DOES NOT WARRANT OR GUARANTEE THE
          ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS OR USEFULNESS OF THE SERVICE.
          REVERSELABZ DOES NOT WARRANT THAT THE SERVICE WILL FUNCTION WITHOUT DELAYS,
          DISRUPTIONS, INTERFERENCES, IMPERFECTIONS, OR ANY ADVERSE INCIDENT.
        </p>

        <p className={styles.title}>1.5 CONSENT</p>

        <p className={styles.paragraph}>
          By enrolling in PlateUp Health program, you are giving consent to the company to
          use your profile info and food images for marketing purposes (on company website
          and the social media)
        </p>

        <p className={styles.title}>1.6 INDEMNIFICATION</p>

        <p className={styles.paragraph}>
          You agree to defend, indemnify, and hold the ReverseLabz Parties harmless from
          and against any and all suits, actions, claims, proceedings, damages,
          settlements, judgments, injuries, liabilities, obligations, losses, risks,
          costs, and expenses (including, without limitation, attorneys’ fees and
          litigation expenses) relating to or arising from the Service, your use of the
          Service, your fraud, violation of law, or willful misconduct, any breach by you
          of these Terms of Use or your violation of any rights of any other person or
          entity. We reserve the right to control the defense of any claim by a
          third-party for which we are entitled to indemnification, and you agree to
          provide us with such cooperation as is reasonably requested by us.
        </p>

        <p className={styles.title}>1.7 LIMITATION OF LIABILITY</p>

        <p className={styles.paragraph}>
          IN NO EVENT SHALL REVERSELABZ BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR
          ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL
          DAMAGES, PERSONAL INJURY OR WRONGFUL DEATH, LOST PROFITS, OR DAMAGES RESULTING
          FROM LOST OR CORRUPTED DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OF
          OR INABILITY TO USE THE SERVICE, INCLUDING ANY INFORMATION MADE AVAILABLE
          THROUGH THE SERVICE OR ANY SERVICES PERFORMED BY THE MEDICAL GROUP OR ITS
          PROVIDER(S) (INCLUDING CLAIMS OF MEDICAL MALPRACTICE), WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND
          WHETHER OR NOT REVERSELABZ ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          REVERSELABZ SHALL BE LIABLE ONLY TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY
          YOU, NOT TO EXCEED U.S. $10.
        </p>
        <p className={styles.paragraph}>
          REVERSELABZ SHALL NOT BE LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, CAUSED
          BY YOUR USE, MISUSE OR INABILITY TO USE THE PLATFORM, THE CONTENT OR THE
          SERVICE. ANY CLAIMS ARISING IN CONNECTION WITH YOUR USE OF THE PLATFORM, SERVICE
          OR CONTENT MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE DATE OF THE EVENT GIVING
          RISE TO SUCH ACTION OCCURRED. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE
          PLATFORM, SERVICE OR CONTENT IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO
          PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR
          USE OF THE PLATFORM, SERVICE OR CONTENT.
        </p>
        <p className={styles.paragraph}>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE
          LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. TO
          THE EXTENT THAT WE MAY NOT DISCLAIM ANY IMPLIED WARRANTY OR LIMIT ITS
          LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF OUR
          LIABILITY WILL BE THE MINIMUM PERMITTED UNDER APPLICABLE LAW.
        </p>

        <p className={styles.title}>Contacting Us</p>

        <p className={styles.paragraph}>
          If you have any questions or concerns about these Terms of Use, please contact
          us at{" "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@reverselabz.com"
            target="_blank"
            rel="noreferrer"
          >
            contact@reverselabz.com.
          </a> We will attempt to respond to your questions or concerns promptly after we
          receive them.
        </p>
        <p className={styles.paragraph}>
          These Terms of Use were last updated on April 20, 2022.
        </p>
      </div>
    </div>
  );
}
