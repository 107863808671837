import clsx from "clsx";
import styles from "./styles.module.scss";

// type RadioPropsType = {
//   label?: string
//   checked: boolean
//   onClick: () => void
//   additionalStyles?: React.CSSProperties
// }

export default function Radio({ checked = false, label, onClick, additionalStyles }) {
  return (
    <div
      className={clsx(
        styles.container,
        checked ? styles.checked : null,
        additionalStyles
      )}
      onClick={onClick}
    >
      <div className={styles.radio}>
        <span></span>
      </div>

      {/* TODO: Style label */}
      {label ? <label>{label}</label> : null}
    </div>
  );
}
