import clsx from "clsx";
import styles from "./styles.module.scss";
import RcCheckbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

export default function Checkbox({
  label,
  identifier,
  checked,
  onChange,
  isError,
  additionalStyles,
}) {
  return (
    <div className={clsx(styles.container, additionalStyles)} onClick={onChange}>
      <RcCheckbox checked={checked} className={isError ? styles.error : null} />
      <label htmlFor={identifier}>{label}</label>
    </div>
  );
}
