import React from 'react';
import styles from "./styles.module.scss"

// import summon from "assets/business_partners/summon.svg"
// import apollo from "assets/business_partners/apollo.svg"
// import healthfeast from "assets/business_partners/healthfeast.svg"
// import wholeheart from "assets/business_partners/wholeheart.svg"
// import wellwoman from "assets/business_partners/wellwoman.svg"
// import sanmateo from "assets/business_partners/sanmateo.svg"

export default function Stats() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthWrapper}>
                <div className={styles.header}>
                    <h1>The <span>challenge</span> at hand</h1>
                    <p>Addressing the root causes to find new and effective ways to empower physicians  to manage their patient health, reduce the burden of chronic disease, and improve overall health outcomes.</p>
                </div>
                <div className={styles.stats}>
                    <div className={styles.stat} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                        <p>{'>'}150 million</p>
                        <p>chronically-ill patients in the United States alone.</p>
                    </div>
                    <span></span>
                    <div className={styles.stat} data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                        <p>6 in 10 adults</p>
                        <p>in the United States have a chronic disease.</p>
                    </div>
                    <span></span>
                    <div className={styles.stat} data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                        <p>$4.3 trillion</p>
                        <p>annual healthcare costs in the United States alone.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
