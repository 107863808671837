import React from 'react'
import styles from "./styles.module.scss"

import educate from "assets/feature_icons/educate.svg"
import wellness from "assets/feature_icons/wellness.svg"
import coaching from "assets/feature_icons/coaching.svg"
import network from "assets/feature_icons/network.svg"
import engagement from "assets/feature_icons/engagement.svg"
import follow from "assets/feature_icons/follow.svg"



const FEATURES = [
  {
    heading: "Educate on health topics",
    description: "Provide patients with resources to help them understand about health and healthcare, including disease prevention, mental health, and overall wellness.",
    image: educate
  },
  {
    heading: "Supportive social network",
    description: "Help patients engage with supportive friends, family members, or coworkers who can help them maintain healthy behaviors.",
    image: network
  },
  {
    heading: "Foster patient engagement",
    description: "Easy-to-use platform that helps patients track and manage their health goals, to provide ongoing care and guidance.",
    image: engagement
  },

  {
    heading: "Workplace wellness",
    description: "Promote workplace wellness and create a healthy environment to improve employee health and productivity.",
    image: wellness
  },
  {
    heading: "Health coaching",
    description: "Offer health coaching services to help patients establish healthy habits and stay motivated to make positive changes.",
    image: coaching
  },
  {
    heading: "People follow people",
    description: "Nudge your patients to follow in the footsteps of a curated list of content creators who have achieved better health.",
    image: follow
  },
]

export default function Features() {
  return (
    <div className={styles.container} id="features">
      <div className={styles.background}></div>
      <div className={styles.overlay}></div>
      <div className={styles.maxWidthContainer}>
        <h1>Why choose <span>PlateUp</span>?</h1>
        <div className={styles.featuresContainer}>
          {FEATURES.slice(0, 3).map((item, index) => <div data-aos="fade-up" data-aos-delay={(index + 1) * 100} data-aos-once="true" key={index} className={styles.feature}>
            <div className={styles.image}>
              <img src={item.image} alt="icon" />
            </div>
            <p>{item.heading}</p>
            <p>{item.description}</p>
          </div>)}
        </div>
        <div className={styles.featuresContainer}>
          {FEATURES.slice(3, 6).map((item, index) => <div data-aos="fade-up" data-aos-delay={(index + 1) * 100} data-aos-once="true" key={index} className={styles.feature}>
            <div className={styles.image}>
              <img src={item.image} alt="icon" />
            </div>
            <p>{item.heading}</p>
            <p>{item.description}</p>
          </div>)}
        </div>
      </div>
    </div>
  )
}
