import React from 'react'
import styles from "./styles.module.scss"

import logo from "assets/raklanding/logo.svg"
import { HashLink } from 'react-router-hash-link'

export default function Navbar() {
  return (
    <div className={styles.container}>
      <div className={styles.maxWidthContainer}>
        <img src={logo} alt="logo" />
        <HashLink to="#form">
          <button>Take your first step</button>
        </HashLink>
      </div>
    </div>
  )
}
