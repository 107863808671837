import React from 'react'
import styles from "./styles.module.scss"

import logo from "assets/raklanding/logo.svg"

import { BsInstagram, BsLinkedin } from "react-icons/bs"

export default function Footer() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <div className={styles.logo}>
                    <img src={logo} alt="" />
                    <p>Dr. Rakesh Jotwani, MD, DipABLM</p>
                </div>
                <div className={styles.text}>
                    <div className={styles.socials}>
                        <button onClick={() => window.open("https://www.instagram.com/rakyourlife/")}>
                            <BsInstagram />
                        </button>
                        <button onClick={() => window.open("https://www.linkedin.com/in/doctorrak/")}>
                            <BsLinkedin />
                        </button>
                    </div>
                    <p>Copyright @ Rakesh Jotwani 2023. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}
