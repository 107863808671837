import React from 'react'
import styles from "./styles.module.scss"

import profile from "assets/raklanding/profile.jpg"

export default function About() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <h1>Meet <span>Dr. Rakesh Jotwani</span></h1>
                <div className={styles.about}>
                    <img src={profile} alt="Dr. Rakesh" />
                    <div className={styles.aboutText}>
                        <p>Dr. Rakesh Jotwani, aka Dr. Rak (“rock”), became passionate about helping others make lifestyle changes after his own health dramatically improved with lifestyle changes. Dr. Rak completed his undergraduate degree in neuroscience at Duke University, his medical degree from the University of Chicago, and his internal medicine residency training at the University of California in San Francisco.</p>

                        <p>He is dual board certified in Internal Medicine and Lifestyle Medicine. He has worked in direct patient care for over a decade, first as a hospital physician, then as a primary care physician. He previously served as the Director of Lifestyle Medicine for a large community hospital in San Francisco. Dr. Rak has an avid interest in how we can effectively change our habits, and regularly speaks about this subject to other healthcare providers across the United States.</p>

                        <p>Dr. Rak is also an active member of the American College of Lifestyle Medicine’s subgroup HEAL (Health Equity Achieved Through Lifestyle). Dr. Rak is married and has three kids. Outside of work, he enjoys spending time cooking and enjoying plant-based meals, hiking, and strength training.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
