import React from 'react';
import styles from "./styles.module.scss"
import { osName } from "react-device-detect";
import { FiArrowRight } from 'react-icons/fi';
import { useState } from 'react';
import { postRequest } from 'utils/requests';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useRef } from 'react';

export default function ContactUs() {

    const [formdata, setFormdata] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const formRef = useRef()

    function onClick() {
        // const os = osName.toLowerCase();
        // if (os === "ios") {
        //     window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
        // } else if (os === "android") {
        //     window.open("https://play.google.com/store/apps/details?id=com.labz2030.reverse");
        // } else {
        //     document.getElementById("downloadbanner").scrollIntoView();
        // }
        window.open("https://calendar.app.google/GUQzehatikKB28Ey6")
    }

    function handleChange(e) {
        let update = { ...formdata }
        update[e.target.name] = e.target.value
        setFormdata(update)
    }

    async function handleSubmit(e) {
        setIsLoading(true)
        e.preventDefault()
        const payload = {
            "subject": "Message from - Website Contact Us",
            "message": `Email: ${formdata.email}\nMessage: ${formdata.message}`
        }
        postRequest("/v1/messages/to_plateup", payload).then((response) => {
            if (response.status === 200) {
                formRef.current.reset()
                toast.success("Message sent!")
            }
        }).catch((error) => {
            console.log(error)
            toast.error("Error occurred, please try again later")
        })
        setIsLoading(false)
    }

    return (
        <div id='contactus' className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <div className={styles.overlay}></div>
                <div className={styles.text}>
                    <h2>Better health, stronger <span>together</span></h2>
                    <p>Got questions? Let us know how we can help and we’ll get in touch with you shortly.</p>
                    <button onClick={onClick}>Request a Demo <FiArrowRight /></button>
                </div>
                <div className={styles.form}>
                    <form ref={formRef} onChange={handleChange} onSubmit={handleSubmit}>
                        <input type="email" name="email" placeholder='Email' required />
                        <textarea name="message" placeholder='Message' required></textarea>
                        <button disabled={isLoading}>
                            {isLoading ? <PulseLoader size={10} color='#FFFFFF' /> : "Submit"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
