import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { useState } from "react";
import AlreadyPaid from "./AlreadyPaid";
import logo from "assets/plateuplogo.svg";
import { getRequest } from "utils/requests";
import Loader from "./Loader";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { toast } from "react-toastify";
import ContactUs from "../../_shared/ContactUs";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function BillPayment() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(false);
  const [billData, setBillData] = useState(null);

  async function createPaymentIntentForBill(bill_id) {
    setIsLoading(true);
    getRequest(`/v1/payments/create_intent_for_bill?bill=${bill_id}`)
      .then((response) => {
        console.log(response);
        setBillData(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong, please try again later.");
      });
    setIsLoading(false);
  }

  useEffect(() => {
    const bill_id = params.get("billing_code");
    if (bill_id) {
      createPaymentIntentForBill(bill_id);
    }
  }, []);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.spinner}>
          <Loader />
        </div>
      ) : null}
      <div className={styles.logo}>
        <img src={logo} alt="" />
        <h1>PlateUp Health</h1>
      </div>

      {billData ? (
        <>
          {billData && billData.status === "unpaid" ? (
            <Elements
              options={{
                clientSecret: billData.client_secret,
                appearance: { theme: "stripe" },
              }}
              stripe={stripePromise}
            >
              <CheckoutForm billData={billData} />
            </Elements>
          ) : billData.status === "paid" ? (
            <AlreadyPaid
              amount={billData.amount}
              medical_practice_name={billData.medical_practice_name}
              service_date={billData.service_date}
              composite_bill_id={billData.composite_bill_id}
              payment_date={billData.payment_date}
              payment_method={billData.payment_method}
            />
          ) : null}
        </>
      ) : null}

      <ContactUs
        subject="Message from - Website Bill Payment"
        additional_info={billData ? billData.composite_bill_id : ""}
      />
    </div>
  );
}
