import Input from "components/_shared/Input";
import styles from "./styles.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postRequest } from "utils/requests";
import Checkbox from "components/_shared/Checkbox";
import Spinner from "components/_shared/Spinner";
import Button from "components/_shared/Button";

export default function DeleteAccount() {
  const [payload, setPayload] = useState({
    email: "",
    reason: "",
  });

  const [isChecked, setIsChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  async function requestAccountDeletion() {
    if (payload.email === "") {
      return toast.error("Email cannot be blank");
    }
    if (payload.reason === "") {
      return toast.error("Reason cannot be blank");
    }
    if (isChecked === false) {
      return toast.error("Please check the acknowledgement");
    }

    setIsLoading(true);

    const body = {
      subject: "Account deletion request",
      message: `Email: ${payload.email}\nMessage: ${payload.reason}`,
    };
    postRequest("/v1/messages/to_plateup", body)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Request submitted", {
            onClose: () => {
              navigate("/");
            },
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error occurred, please try again later");
      });

    setIsLoading(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h3>Request account deletion</h3>

        <div className={styles.intro}>
          <p>We’re sorry to see you go!</p>
          <p>
            If you wish to delete your account, please read the information below and
            confirm your request.
          </p>
        </div>

        <div className={styles.info}>
          <p className={styles.title}>Important information:</p>

          <p className={styles.point}>
            <span>1. Review Process:</span> Your account deletion request will be reviewed
            by our team. We will notify you via email once the review is complete.
          </p>
          <p className={styles.point}>
            <span>2. Permanent Deletion:</span> Once approved, deleting your account is
            permanent and cannot be undone. All your data, including profile information,
            activity history, and settings, will be permanently removed from our system.
          </p>
          <p className={styles.point}>
            <span>3. Data Retention:</span> Certain data may be retained for legal or
            administrative purposes as required by law.
          </p>
          <p className={styles.point}>
            <span>4. Account Access:</span> Once your account is deleted, you will no
            longer be able to access any services or content associated with your account.
          </p>
        </div>

        <Input
          fullWidth
          type="email"
          label="Email"
          placeholder="Enter your email address"
          additionalStyles={styles.email}
          onChange={(e) => {
            setPayload((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
        <Input
          fullWidth
          type="text"
          label="Reason for account deletion"
          placeholder="Enter your reason"
          onChange={(e) => {
            setPayload((prev) => ({ ...prev, reason: e.target.value }));
          }}
        />

        <Checkbox
          label="I understand that my request will be reviewed, and my data will be removed upon approval."
          identifier="delete-account-confirm"
          checked={isChecked}
          onChange={() => {
            setIsChecked((prev) => !prev);
          }}
          additionalStyles={styles.checkbox}
        />

        <Button
          size="big"
          type="primary"
          text={isLoading ? <Spinner buttonLoader /> : "Request deletion"}
          backgroundColor="#DA3C3C"
          borderColor="#DA3C3C"
          textColor="#ffffff"
          additionalStyles={styles.cta}
          onClick={requestAccountDeletion}
        />
      </div>
    </div>
  );
}
