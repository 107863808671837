import React from "react";
import styles from "./Navbar.module.scss";
import { HashLink } from 'react-router-hash-link';
import { osName } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import { useScrollPosition } from "hooks/useScrollPosition";
import { useState } from "react";
import { useEffect } from "react";


export default function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const [scrolling, setScrolling] = useState(false)
  const [navbarColor, setNavbarColor] = useState("#FFFBF3")

  // function checkRouteThenScroll(el) {
  //   console.group("click")
  //   if (location.pathname === "/") {
  //     el.scrollIntoView()
  //   } else {
  //     navigate("/")
  //     el.scrollIntoView()
  //   }
  // }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }




  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -200) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    },
    [location]
  );

  useEffect(() => {
    if (location.pathname.includes("blogs") || location.pathname.includes("delete")) {
      setNavbarColor("#FFFBF3")
    } else {
      setNavbarColor("#FFFBF3")
    }
    // console.log(location.pathname.includes("blogs"))
  }, [location])

  return (
    <nav className={styles.wrapper} style={{
      backgroundColor: scrolling ? navbarColor : "transparent"
    }}>
      <div className={styles.maxWidthContainer}>
        <h1 onClick={() => {
          navigate("/")
        }} style={{ color: scrolling ? "#000000" : "#FFFFFF" }}>PlateUp Health</h1>
        <div className={styles.links}>
          <HashLink to="/#features" scroll={el => scrollWithOffset(el)} style={{ color: scrolling ? "#000000" : "#FFFFFF" }}>
            Features
          </HashLink>
          <HashLink to="/#medicalproviders" scroll={el => scrollWithOffset(el)} style={{ color: scrolling ? "#000000" : "#FFFFFF" }}>
            Medical providers
          </HashLink>
          <HashLink to="/#employers" scroll={el => scrollWithOffset(el)} style={{ color: scrolling ? "#000000" : "#FFFFFF" }} >
            Employers
          </HashLink>
          <HashLink to="/#pricing" scroll={el => scrollWithOffset(el)} style={{ color: scrolling ? "#000000" : "#FFFFFF" }}>
            Pricing
          </HashLink>
          <HashLink to="/#blogs" scroll={el => scrollWithOffset(el)} style={{ color: scrolling ? "#000000" : "#FFFFFF" }}>
            Blogs
          </HashLink>

        </div>
        <HashLink to="#contactus"
        // scroll={(el) => {
        //   const os = osName.toLowerCase();
        //   if (os === "ios") {
        //     window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
        //   } else if (os === "android") {
        //     window.open("https://play.google.com/store/apps/details?id=com.labz2030.reverse");
        //   } else {
        //     el.scrollIntoView()
        //   }
        // }}
        >
          <button>
            Get in touch
          </button>
        </HashLink>
        <HashLink to="#contactus" className={styles.mobileButton}>
          Get in touch
        </HashLink>
      </div>
    </nav >
  );
}
