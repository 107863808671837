import clsx from "clsx";
import styles from "./styles.module.scss";

// type TextareaPropsType = {
//   label?: string | React.ReactElement
//   placeholder?: string
//   onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
//   isError?: boolean
//   textareaProps?: any
//   additionalStyles?: React.CSSProperties
// }

export default function Textarea({
  label,
  placeholder,
  onChange,
  isError,
  textareaProps,
  additionalStyles,
}) {
  return (
    <div
      className={clsx(styles.container, isError ? styles.error : null, additionalStyles)}
    >
      {label ? <label>{label}</label> : null}
      <textarea
        {...textareaProps}
        placeholder={placeholder}
        onChange={onChange}
      ></textarea>
    </div>
  );
}
