import React from 'react'
import styles from "./styles.module.scss"
import { FiArrowRight } from "react-icons/fi"
import { HashLink } from 'react-router-hash-link';

import { ParallaxBanner } from 'react-scroll-parallax';




export default function Hero() {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <div className={styles.container}>
            <ParallaxBanner
                layers={[{ image: window.screen.width > 1000 ? 'https://d1shve8gekzify.cloudfront.net/website-assets/hero_new.jpg' : 'https://d1shve8gekzify.cloudfront.net/website-assets/hero-mobile2-min.jpg', speed: -50, expanded: false }]}
                className={styles.background}
            />
            <div className={styles.maxWidthContainer}>
                <div className={styles.heroText}>
                    <h1>Creating <span>ecosystems</span> for improved health outcomes.</h1>
                    <p>We believe the future of health lies with <span>physicians</span> positively impacting their patients' environment at home and work.</p>
                    <HashLink to="/#affiliates" scroll={el => scrollWithOffset(el)}>
                        Explore now <FiArrowRight />
                    </HashLink>
                    <p className={styles.phone}><a href="tel:+16503149535">or call us: (650) 314-9535</a></p>
                </div>
            </div>

        </div>
    )
}
