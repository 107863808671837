import React from 'react'
import styles from "./styles.module.scss"

import { AiFillStar } from 'react-icons/ai'

export default function Testimonials() {
    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <div className={styles.card}>
                    <div className={styles.rating}>
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                    </div>
                    <p>Get PlateUp now!</p>
                    <p>I wholeheartedly endorse PlateUp Health. PlateUp's impact is tangible, leaving both me and my patients delighted.</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.rating}>
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                    </div>
                    <p>Exceptional</p>
                    <p>Platform simplifies lifestyle prescription and tracking, embracing the six pillars of lifestyle medicine as recommended by ACLM.</p>
                </div>
                <div className={styles.card}>
                    <div className={styles.rating}>
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                    </div>
                    <p>Outstanding experience!</p>
                    <p>Our patient's adore PlateUp Health's lifestyle recommendations. Enhance your patient's care journey with PlateUp today!</p>
                </div>
            </div>
        </div>
    )
}
