import React from 'react'
import styles from "./styles.module.scss"

import bloodpressure from "assets/evidence_icons/bloodpressure.svg"
import heart from "assets/evidence_icons/heart.svg"
import care from "assets/evidence_icons/care.svg"
import platform from "assets/evidence_icons/platform.svg"
import people from "assets/evidence_icons/people.svg"
import weight from "assets/evidence_icons/weight.svg"
import books from "assets/evidence_icons/books.svg"
import spoon from "assets/evidence_icons/spoon.svg"
import meds from "assets/evidence_icons/meds.svg"

import arrow from "assets/arrow-down.svg"

import { FiArrowUpCircle } from 'react-icons/fi'
import { useRef } from 'react'


const STUDIES = [
    {
        id: 1,
        link: "https://journals.sagepub.com/doi/full/10.1177/15598276221090887",
        image: heart,
        heading: "Why lifestyle medicine?",
        desc: "Lifestyle medicine interventions are a prescription for individual, community, and planetary health, addressing both chronic conditions and the planetary crisis."
    },
    {
        id: 2,
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5389392/",
        image: people,
        heading: "Why health coaches?",
        desc: "Research shows that health coaches can provide personalized support, improve patient activation, and lead to better health outcomes."
    },
    {
        id: 3,
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3490012/",
        image: platform,
        heading: "Why a social platform?",
        desc: "Social support is associated with improved clinical outcomes and lifestyle changes in adults, highlighting the importance of community in management."
    },
    {
        id: 4,
        link: "https://www.ahajournals.org/doi/full/10.1161/JAHA.120.020718",
        image: care,
        heading: "Reducing heart disease",
        desc: "Evidence-based intervention to reverse heart disease.",
        footer_num: 54,
        footer_text: "REDUCED HEART ATTACK RISK",
    },
    {
        id: 5,
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2671114/",
        image: spoon,
        heading: "Reduce Type 2 diabetes",
        desc: "Remove  foods causing damage and add healthy healing foods.",
        footer_num: 49,
        footer_text: "LESS LIKELY TO BE DIABETIC",
    },
    {
        id: 6,
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2671114/",
        image: meds,
        heading: "Lower cholesterol",
        desc: "Eliminate cholesterol foods, lower cholesterol significantly.",
        footer_num: 35,
        footer_text: "LOWER CHOLESTEROL",
    },
    {
        id: 7,
        link: "https://pubmed.ncbi.nlm.nih.gov/22230619/",
        image: bloodpressure,
        heading: "Lowering blood pressure",
        desc: "Active lifestyle and healthy diet lower BP in as little as 7 days.",
        footer_num: 60,
        footer_text: "REDUCED RISK OF HIGH BP",
    },
    {
        id: 8,
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8147427/",
        image: weight,
        heading: "Weight management",
        desc: "Weight loss through unrestricted nutrient-dense plant-based diet.",
        footer_num: 69,
        footer_text: "REDUCED RISK OF OBESITY",
    },
    {
        id: 9,
        link: "https://lifestylemedicine.org/",
        image: books,
        heading: "1000+ more studies",
        desc: "The American College of Lifestyle Medicine has a website featuring over 1000 published studies. Read through these studies.",
    },
]

export default function Evidence() {
    const swiperElRef = useRef(null);

    function openLink(link) {
        window.open(link)
    }

    return (
        <div className={styles.container}>
            <div className={styles.overlay}></div>
            {/* <div className={styles.gradient}></div> */}
            <div className={styles.maxWidthContainer}>
                <h1>Discover the <span>science</span> that drives us.</h1>
                <div className={styles.desktop}>
                    <div className={styles.row} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                        {STUDIES.slice(0, 3).map((item) => <div key={item.id} className={styles.card} onClick={openLink.bind(this, item.link)}>
                            <div className={styles.mainContent}>
                                <div className={styles.header}>
                                    <img src={item.image} alt="icon" />
                                    <FiArrowUpCircle />
                                </div>
                                <p>{item.heading}</p>
                                <p>{item.desc}</p>
                            </div>
                            {item.footer_num ? <div className={styles.cardFooter}>
                                <img src={arrow} alt="down arrow" /> <span>{item.footer_num}%</span> <p>{item.footer_text}</p>
                            </div> : null}
                        </div>)}
                    </div>
                    <div className={styles.row} data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                        {STUDIES.slice(3, 6).map((item) => <div key={item.id} className={styles.card} onClick={openLink.bind(this, item.link)}>
                            <div className={styles.mainContent}>
                                <div className={styles.header}>
                                    <img src={item.image} alt="heart" />
                                    <FiArrowUpCircle />
                                </div>
                                <p>{item.heading}</p>
                                <p>{item.desc}</p>
                            </div>
                            {item.footer_num ? <div className={styles.cardFooter}>
                                <img src={arrow} alt="down arrow" /> <span>{item.footer_num}%</span> <p>{item.footer_text}</p>
                            </div> : null}
                        </div>)}
                    </div>
                    <div className={styles.row} data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                        {STUDIES.slice(6, 9).map((item) => <div key={item.id} className={styles.card} onClick={openLink.bind(this, item.link)}>
                            <div className={styles.mainContent}>
                                <div className={styles.header}>
                                    <img src={item.image} alt="heart" />
                                    <FiArrowUpCircle />
                                </div>
                                <p>{item.heading}</p>
                                <p>{item.desc}</p>
                            </div>
                            {item.footer_num ? <div className={styles.cardFooter}>
                                <img src={arrow} alt="down arrow" /> <span>{item.footer_num}%</span> <p>{item.footer_text}</p>
                            </div> : null}
                        </div>)}
                    </div>
                </div>
                <div className={styles.mobile} data-aos="fade-up" data-aos-once="true">
                    <swiper-container
                        ref={swiperElRef}
                        slides-per-view={window.screen.width < 768 ? 1.2 : 2.2}
                        navigation="false"
                        pagination="false"
                    >
                        {STUDIES.map((item) => <swiper-slide key={item.id}>
                            <div className={styles.card} onClick={openLink.bind(this, item.link)}>
                                <div className={styles.mainContent}>
                                    <div className={styles.header}>
                                        <img src={item.image} alt="heart" />
                                        <FiArrowUpCircle />
                                    </div>
                                    <p>{item.heading}</p>
                                    <p>{item.desc}</p>
                                </div>
                                {item.footer_num ? <div className={styles.cardFooter}>
                                    <img src={arrow} alt="down arrow" /> <span>{item.footer_num}%</span> <p>{item.footer_text}</p>
                                </div> : null}
                            </div>
                        </swiper-slide>)}
                    </swiper-container>
                </div>
            </div>
        </div>
    )
}
