import React from "react";
import styles from "./styles.module.scss";

import { BiHelpCircle } from "react-icons/bi";
import { FiX } from "react-icons/fi";
import { useState } from "react";
import { postRequest } from "utils/requests";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function ContactUs({ openModal, subject, additional_info }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formdata, setFormdata] = useState({});

  function handleChange(e) {
    let update = { ...formdata };
    update[e.target.name] = e.target.value;
    setFormdata(update);
  }

  async function onSubmit(e) {
    e.preventDefault();

    const payload = {
      subject: subject ? subject : "Message from - Website",
      message: `Email: ${formdata.email}\nMessage: ${formdata.message}\nAdditional Info: ${additional_info}`,
    };
    postRequest("/v1/messages/to_plateup", payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Message sent!");
          setIsModalOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error occurred, please try again later");
      });
  }

  useEffect(() => {
    setIsModalOpen(openModal);
  }, [openModal]);

  return (
    <>
      <div className={styles.button}>
        <button onClick={() => setIsModalOpen(true)}>
          <BiHelpCircle />
          Need help?
        </button>
      </div>

      {isModalOpen ? (
        <div className={styles.modalContainer}>
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>Contact Us</p>
              <button onClick={() => setIsModalOpen(false)}>
                <FiX />
              </button>
            </div>
            <form onChange={handleChange} onSubmit={onSubmit}>
              <input type="email" name="email" placeholder="Email" required />
              <textarea placeholder="Message" name="message" required></textarea>
              <button>Send message</button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}
