import clsx from "clsx";
import styles from "./styles.module.scss";

import ReactSelect from "react-select";
import React from "react";

// type SelectOption = {
//   value: string
//   label: string
// }

// type SelectPropsType = {
//   isError?: boolean
//   options: SelectOption[]
//   label?: string | React.ReactElement
//   placeholder?: string
//   onChange: (option: SelectOption | null) => void
//   defaultValue?: SelectOption | null
//   value?: SelectOption | null
//   additionalStyles?: React.CSSProperties
// }

export default function Select({
  isError,
  label,
  placeholder,
  options,
  onChange,
  defaultValue,
  value,
  additionalStyles,
}) {
  return (
    <div
      className={clsx(styles.container, isError ? styles.error : null, additionalStyles)}
    >
      {label ? <label>{label}</label> : null}

      <ReactSelect
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        classNamePrefix="select"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#4384E6" : isError ? "#E8505B" : "#E7E7E7",
            minHeight: "2.45rem",
            height: "2.45rem",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: "2.45rem",
            padding: "0.625rem 1rem",
          }),

          input: (provided, state) => ({
            ...provided,
            margin: "0rem",
            paddingBottom: "0",
            paddingTop: "0",
          }),
          indicatorSeparator: (state) => ({
            display: "none",
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "2.45rem",
          }),
        }}
        className={styles.select}
        options={options}
        onChange={onChange}
        isSearchable={false}
      />
    </div>
  );
}
