import React from "react";
import { useEffect } from "react";
import styles from "./Home.module.scss";

import { useLocation } from "react-router-dom";
import triggerEvent from "firebaseConfig/firebase";
import Banner from "./Banner";
import Hero from "./Hero";
import Features from "./Features";
import GetInTouch from "./GetInTouch";
import Stats from "./Stats";
import Result from "./Results";
import Pricing from "./Pricing";
import Affiliates from "./Affiliates";
import Evidence from "./Evidence";
import Blogs from "./Blogs";
import Team from "./Team";
import Insurances from "./Insurances";
import ContactUs from "./ContactUs";
import Testimonials from "./Testimonials";
import Founders from "./Founders";
// import InteractiveVideo from "./InteractiveVideo";

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    const { search } = location;

    if (search) {
      const platform = search.split("=")[1];
      triggerEvent(`${platform}`);
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      {/* <InteractiveVideo /> */}
      <Hero />
      <Affiliates />
      <Features />
      <Testimonials />
      <Insurances />
      <Stats />
      <Result />
      <Banner />
      <Team />
      <Pricing />
      <Founders />
      <GetInTouch />
      <Evidence />
      <Blogs />
      <ContactUs />
    </div>
  );
}
