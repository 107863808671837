import React from 'react'
import styles from "./styles.module.scss"

import Navbar from './Navbar'
import Hero from './Hero'
import About from './About'
import Testimonials from './Testimonials'
import ChronicConditions from './ChronicConditions'
import Features from './Features'
import Faqs from './Faqs'
import Form from './Form'
import Footer from './Footer'

export default function RakLanding() {
  return (
    <div className={styles.container}>
      <Navbar />
      <Hero />
      <About />
      <Features />
      <ChronicConditions />
      <Testimonials />
      <Faqs />
      <Form />
      <Footer />
    </div>
  )
}
