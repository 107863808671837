import React from "react";
import styles from "./Footer.module.scss";

import plateuplogo from "assets/plateuplogo.svg";

import playstorebutton from "assets/playstorebutton.png";
import appstorebutton from "assets/appstorebutton.png";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import triggerEvent from "firebaseConfig/firebase";

import hipaa from "assets/hipaa.svg"

export default function Footer() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay}></div>
      <div className={styles.maxWidthWrapper}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <img src={plateuplogo} alt="PlateUp Logo" />
            <div>
              <p>PlateUp Health</p>
              <p>Better health, stronger together.</p>
            </div>
          </div>
          <div className={styles.socials}>
            <img src={hipaa} alt="" />
            <button onClick={() => window.open("https://www.instagram.com/plateup.health/")}>
              <BsInstagram />
            </button>
            <button onClick={() => window.open("https://www.linkedin.com/company/reverselabz/")}>
              <BsLinkedin />
            </button>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.buttons}>
            {/* <button
              onClick={() => {
                triggerEvent("app_store_button_click");
                window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
              }}
            >
              <img src={appstorebutton} alt="" />
            </button>
            <button
              onClick={() => {
                triggerEvent("play_store_button_click");
                window.open(
                  "https://play.google.com/store/apps/details?id=com.labz2030.reverse"
                );
              }}
            >
              <img src={playstorebutton} alt="" />
            </button> */}
          </div>

          <div className={styles.text}>
            <p>PlateUp Health is a product of Reverse Labz inc.</p>
            <p>Copyright @ ReverseLabz 2023. All rights reserved.</p>
          </div>
        </div>
        {/* <div className={styles.left}>
          <img className={styles.logo} src={plateuplogo} alt="" />
          <h2>PlateUp Health</h2>
          <p>Better health, stronger together.</p>
          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                triggerEvent("app_store_button_click");
                window.open("https://apps.apple.com/us/app/plateup-health/id1608407957");
              }}
            >
              <img src={appstorebutton} alt="" />
            </button>
            <button
              onClick={() => {
                triggerEvent("play_store_button_click");
                window.open(
                  "https://play.google.com/store/apps/details?id=com.labz2030.reverse"
                );
              }}
            >
              <img src={playstorebutton} alt="" />
            </button>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.logos}>
            <img src={reverselabz} alt="reverselabz logo" />
            <button
              onClick={() => window.open("https://www.instagram.com/plateup.health/")}
            >
              <BsInstagram />
            </button>
            <button
              onClick={() => window.open("https://www.linkedin.com/company/reverselabz/")}
            >
              <BsLinkedin />
            </button>
          </div>
          <p>
            PlateUp Health is a product of
          </p>
          <div className={styles.reverse}>
            <img src={reverselabz} alt="reverselabz logo" /> <p>ReverseLabz Inc.</p>
          </div>
          <p>Copyright @ ReverseLabz{` ${new Date().getFullYear()}`}. All rights reserved.</p>
        </div> */}
      </div>
    </div>
  );
}
