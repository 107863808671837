import React from 'react'
import styles from "./styles.module.scss"

import { FiPlus, FiArrowUp } from 'react-icons/fi'
import { useState } from 'react'

export default function Faqs() {
    const [activeAccordion, setActivAccordion] = useState(null)

    function openAccordion(index) {
        setActivAccordion((prev) => {
            if (prev === index) {
                return null
            } else {
                return index
            }
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.maxWidthContainer}>
                <h1>FAQs</h1>
                <div className={styles.accordion} onClick={() => openAccordion(1)}>
                    <div className={styles.header}>
                        <p>What exactly is lifestyle medicine?</p>
                        <FiPlus />
                    </div>
                    {activeAccordion === 1 ? <div className={styles.content}>
                        <p>Lifestyle Medicine places significant emphasis on evidence-based, non-pharmaceutical interventions, such as dietary modifications, physical activity, mindfulness practices, and related areas, serving as the primary approach for treating and preventing numerous diseases. It primarily targets the underlying causes of chronic conditions or illnesses instead of merely addressing the symptoms commonly treated with medications.</p>
                    </div> : null}

                </div>
                <div className={styles.accordion} onClick={() => openAccordion(2)}>
                    <div className={styles.header}>
                        <p>What are the advantages of lifestyle medicine?</p>
                        <FiPlus />
                    </div>
                    {activeAccordion === 2 ? <div className={styles.content}>
                        <p>Embracing lifestyle medicine has immense potential to bestow many advantages on your health and well-being. From heightened happiness and diminished reliance on medications to notable weight loss and beyond, its positive impact on various health conditions is profound.</p>
                    </div> : null}

                </div>
                <div className={styles.accordion} onClick={() => openAccordion(3)}>
                    <div className={styles.header}>
                        <p>What is the role of preventative medicine?</p>
                        <FiPlus />
                    </div>
                    {activeAccordion === 3 ? <div className={styles.content}>
                        <p>Preventive medicine takes a proactive stance, focusing on maintaining a balanced lifestyle through healthy eating, regular exercise, and other factors. Rather than dealing with chronic conditions after they arise, this approach aims to keep health risk factors at bay. Making informed lifestyle choices is pivotal in securing significant long-term benefits for your well-being.</p>
                    </div> : null}

                </div>
            </div>
        </div>
    )
}
