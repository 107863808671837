import React from 'react'
import styles from "./styles.module.scss"

import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useRef } from 'react'

export default function Form() {
    const [formdata, setFormdata] = useState({})
    const formRef = useRef()

    function handleChange(e) {
        let update = { ...formdata }
        update[e.target.name] = e.target.value
        setFormdata(update)
    }

    function handleSubmit(e) {
        e.preventDefault()
        const payload = {
            "message": `Name: ${formdata.name}\nEmail: ${formdata.email}\nPhone: ${formdata.phone}\nState: ${formdata.state}\nMessage: ${formdata.message}
                        `
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/messages/from_website`, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((resp) => {
            if (resp.status === 200) {
                toast.success("Message sent!")
                formRef.current.reset()
            }
        }).catch((err) => {
            console.log(err)
            toast.error("Please try again later")
        })

    }



    return (
        <div className={styles.container} id="form">
            <div className={styles.maxWidthContainer}>
                <div className={styles.left}>
                    <h1><span>You're not alone;</span><br />
                        I'm here for you on<br />this journey!</h1>
                    <p>Join me on your journey! I’m here to support you every step of the way. Share your details and  I’ll reach back to you!</p>
                </div>
                <div className={styles.right}>
                    <form onChange={handleChange} onSubmit={handleSubmit} ref={formRef}>
                        <input type="text" name='name' placeholder='Name' required />
                        <input type="email" name='email' placeholder='Email' required />
                        <input type="number" name='phone' placeholder='Phone' required />
                        <input type="text" name='state' placeholder='State' required />
                        <textarea name="message" placeholder='Message (optional)'></textarea>
                        <button>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
